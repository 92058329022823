"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _assign2 = _interopRequireDefault(require("lodash/assign"));
var _mapValues = _interopRequireDefault(require("lodash/mapValues"));
var _values = _interopRequireDefault(require("lodash/values"));
var _some = _interopRequireDefault(require("lodash/some"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } // LIBRARIES
// import { actions as selectedActions } from '../selected'

// ENTITIES UTILITIES

// ACTIONS
var actionTypes = {
  TOGGLE_LAYER: 'KEOLIS-MAP/TOGGLE_LAYER',
  TOGGLE_ALL: 'KEOLIS-MAP/TOGGLE_ALL'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  toggleLayer: function toggleLayer(payload) {
    return {
      type: actionTypes.TOGGLE_LAYER,
      payload: payload
    };
  },
  toggleAll: function toggleAll(payload) {
    return {
      type: actionTypes.TOGGLE_ALL,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_LAYER:
      var layer = action.payload;
      return (0, _assign2["default"])({}, state, _defineProperty({}, layer, !state[layer]));
    case actionTypes.TOGGLE_ALL:
      var allSelected = !(0, _some["default"])((0, _values["default"])(state), function (l) {
        return !l;
      });
      return (0, _mapValues["default"])(state, function () {
        return !allSelected;
      });
    default:
      return state;
  }
}
var epic = function epic(action$, store) {
  return action$.ofType(actionTypes.TOGGLE_LAYER).map(function (action) {
    return selectedActions.deselect();
  })["catch"](function (err) {
    return console.log(err);
  });
};
exports.epic = epic;