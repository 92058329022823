/*eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Msal from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

/* NOTE: for reasons I can't explain, these rxjs modules that are
used by the map component library need to be explicitly imported
here in our project. Removing these imports will likely break things. */
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/startWith';

import { msalConfig } from './authConfig';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import store from './store/index';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// console.log('window url', window.location.href);
console.log('index.js', window.location.href);

const msalInstance = new Msal.PublicClientApplication(msalConfig);

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
  </MsalProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
