"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _mapValues = _interopRequireDefault(require("lodash/mapValues"));
var _values = _interopRequireDefault(require("lodash/values"));
var _keys = _interopRequireDefault(require("lodash/keys"));
var _pick = _interopRequireDefault(require("lodash/pick"));
var _some = _interopRequireDefault(require("lodash/some"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _actions = require("../actions");
var _api = _interopRequireDefault(require("../../api"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// GlOBAL ACTIONS

// ACTIONS
var actionTypes = {
  SET_MILEPOSTS: 'KEOLIS-MAP/SET_MILEPOSTS'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  setMileposts: function setMileposts(payload) {
    return {
      type: actionTypes.SET_MILEPOSTS,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_MILEPOSTS:
      return action.payload;
    default:
      return state;
  }
}

// EPIC
var epic = function epic(action$, store) {
  return action$.ofType('__DEPRECATED').switchMap(function (action) {
    return (0, _api["default"])(store).get('system/mileposts.json').map(function (result) {
      return result.response;
    }).map(function (mileposts) {
      return actions.setMileposts(mileposts);
    })["catch"](function (error) {
      return _rxjs.Observable.of({}).ignoreElements();
    });
  });
};
exports.epic = epic;