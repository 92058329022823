"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = void 0;
var _rxjs = require("rxjs");
var _store = _interopRequireDefault(require("../../../store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// MAP COMPONENT ACTIONS

// EPIC
var epic = function epic(action$, store) {
  return action$.ofType(_store["default"].INIT).map(function (action) {
    return localStorage.getItem('map-filter-prod');
  }).filter(function (layers) {
    return layers;
  }).map(function (layers) {
    return JSON.parse(layers);
  }).map(function (layers) {
    return _store["default"].setLayers(layers);
  });
};
exports.epic = epic;