"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _reduxObservable = require("redux-observable");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _actions = require("../actions");
var _layers = require("../layers");
var _load = require("./load");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// ACTIONS

// ACTIONS
var actionTypes = {
  START_POLLING_RADAR: 'KEOLIS-MAP/START_POLLING_RADAR',
  STOP_POLLING_RADAR: 'KEOLIS-MAP/STOP_POLLING_RADAR'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  startPollingRadar: function startPollingRadar(payload) {
    return {
      type: actionTypes.START_POLLING_RADAR,
      payload: payload
    };
  },
  stopPollingRadar: function stopPollingRadar(payload) {
    return {
      type: actionTypes.STOP_POLLING_RADAR,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.START_POLLING_RADAR:
      return (0, _assign["default"])({}, state, {
        isPolling: true
      });
    case actionTypes.STOP_POLLING_RADAR:
      return (0, _assign["default"])({}, state, {
        isPolling: false
      });
    default:
      return state;
  }
}

// EPICS

var baseTriggerActions = [_actions.INIT, _layers.actionTypes.SET_LAYERS, _layers.actionTypes.TOGGLE_ALL, _layers.actionTypes.TOGGLE_LAYER, _actions.SET_TIMESTAMP];
var startTriggers = [_actions.START_POLLING].concat(baseTriggerActions);
var stopTriggers = [_actions.STOP_POLLING].concat(baseTriggerActions);

// start polling
var startPollingEpic = function startPollingEpic(action$, store) {
  return action$
  // Filter of actions which could affect polling state
  .filter(function (action) {
    return (0, _includes["default"])(startTriggers, action.type);
  }).filter(function (action) {
    // Check if we're not polling but should be
    var _store$getState = store.getState(),
      map = _store$getState.map;
    return map.layers.radar && !map.playback.timestamp && !map.radar.isPolling;
  }).map(function () {
    return actions.startPollingRadar();
  })["catch"](function (error) {
    return console.log(error);
  });
};

// stop polling
var stopPollingEpic = function stopPollingEpic(action$, store) {
  return action$.filter(function (action) {
    return (0, _includes["default"])(stopTriggers, action.type);
  }).filter(function (action) {
    // Check if we're polling, but shouldn't be
    var _store$getState2 = store.getState(),
      map = _store$getState2.map;
    return map.radar.isPolling && (!map.layers.radar || map.playback.timestamp);
  }).map(function () {
    return actions.stopPollingRadar();
  });
};

// Actually poll
var pollingEpic = function pollingEpic(action$, store) {
  return action$.ofType(actionTypes.START_POLLING_RADAR).switchMap(function (action) {
    return (
      // Start Polling
      _rxjs.Observable.interval(60 * 1000).startWith({}).map(function () {
        return _load.actions.loadCurrentRadar();
      })
      // Stop polling logic
      .takeUntil(action$.ofType(actionTypes.STOP_POLLING_RADAR))
    );
  });
};

// Merge epics
var epic = (0, _reduxObservable.combineEpics)(startPollingEpic, stopPollingEpic, pollingEpic);
exports.epic = epic;