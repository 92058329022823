"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.actions = exports.actionTypes = void 0;
var _assign = _interopRequireDefault(require("lodash/assign"));
var _omit = _interopRequireDefault(require("lodash/omit"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var actionTypes = {
  REGION: "REGION",
  ZOOM_TO: "ZOOM_TO"
};
exports.actionTypes = actionTypes;
var actions = {
  setRegion: function setRegion(payload) {
    return {
      type: actionTypes.REGION,
      payload: (0, _assign["default"])({}, payload, {
        isZoomed: false
      })
    };
  },
  zoomTo: function zoomTo(latLong) {
    return {
      type: actionTypes.ZOOM_TO,
      payload: {
        latitude: latLong[0],
        longitude: latLong[1],
        latitudeDelta: 0.01,
        longitudeDelta: 0.01,
        isZoomed: true
      }
    };
  }
};
exports.actions = actions;
var initialState = {
  isZoomed: false,
  latitude: 42.41958835540104,
  longitude: -71.1892353902494,
  latitudeDelta: 1.3781218363020855,
  longitudeDelta: 1.2659217928518274
};

// REDUCER
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.REGION:
      return action.payload;
    case actionTypes.ZOOM_TO:
      // do action if deltas are greater than 0.01
      if (state.latitudeDelta > 0.01 || state.longitudeDelta > 0.01) return action.payload;else return (0, _assign["default"])({}, state, (0, _omit["default"])(action.payload, 'latitudeDelta', 'longitudeDelta'));
    default:
      return state;
  }
};
exports.reducer = reducer;