"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _reduxObservable = require("redux-observable");
var _api = _interopRequireDefault(require("../../api"));
var _utilities = require("../../utilities");
var _actions = require("../actions");
var _layers = require("../layers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// ACTIONS
var actionTypes = {
  LOAD_CURRENT_RADAR: 'KEOLIS-MAP/LOAD_CURRENT_RADAR',
  SET_RADAR: 'KEOLIS-MAP/SET_RADAR',
  RADAR_SET_LOADING: 'KEOLIS-MAP/RADAR_SET_LOADING',
  SET_RADAR_WITH_TIMESTAMP: 'KEOLIS-MAP/SET_RADAR_WITH_TIMESTAMP'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  loadCurrentRadar: function loadCurrentRadar(payload) {
    return {
      type: actionTypes.LOAD_CURRENT_RADAR,
      payload: payload
    };
  },
  setRadar: function setRadar(payload) {
    return {
      type: actionTypes.SET_RADAR,
      payload: payload
    };
  },
  setRadarWithTimestamp: function setRadarWithTimestamp(payload) {
    return {
      type: actionTypes.SET_RADAR_WITH_TIMESTAMP,
      payload: payload
    };
  },
  setRadarLoading: function setRadarLoading(payload) {
    return {
      type: actionTypes.RADAR_SET_LOADING,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_RADAR:
      var radar = action.payload;
      return (0, _assign["default"])({}, state, {
        cache: (0, _utilities.reduceRadarList)({}, [radar]),
        radar: radar,
        isLoading: false,
        maxLoadedDate: null
      });
    case actionTypes.SET_RADAR_WITH_TIMESTAMP:
      var _action$payload = action.payload,
        list = _action$payload.list,
        timestamp = _action$payload.timestamp;
      var nextCache = (0, _utilities.reduceRadarList)({}, list);
      return (0, _assign["default"])({}, state, {
        cache: nextCache,
        radar: (0, _utilities.getDataForTimestamp)(nextCache, timestamp),
        isLoading: false,
        maxLoadedDate: timestamp,
        isBuffering: false
      });
    case actionTypes.LOAD_CURRENT_RADAR:
      return (0, _assign["default"])({}, state, {
        isLoading: true
      });
    case _actions.SET_TIMESTAMP:
      return (0, _assign["default"])({}, state, {
        cache: {},
        radar: action.payload ? null : state.radar
      });
    case actionTypes.RADAR_SET_LOADING:
      return (0, _assign["default"])({}, state, {
        isLoading: true
      });
    default:
      return state;
  }
}
var baseTriggerActions = [_actions.SET_TIMESTAMP, _layers.actionTypes.SET_LAYERS, _layers.actionTypes.TOGGLE_ALL, _layers.actionTypes.TOGGLE_LAYER];

// EPIC
var loadCurrentEpic = function loadCurrentEpic(action$, store) {
  return action$.ofType(actionTypes.LOAD_CURRENT_RADAR).switchMap(function (action) {
    return (0, _api["default"])(store).get('maps/radar/current.json').map(function (result) {
      return result.response;
    }).map(function (radar) {
      return actions.setRadar(radar);
    })["catch"](function (error) {
      console.log(error);
      return _rxjs.Observable.of({}).ignoreElements();
    });
  });
};
var loadTimestampEpic = function loadTimestampEpic(action$, store) {
  return action$.filter(function (action) {
    return (0, _includes["default"])(baseTriggerActions, action.type);
  })
  // check if this for a timestamp in the past (will be null for current)
  .filter(function (action) {
    return action.type == _actions.SET_TIMESTAMP ? action.payload : true;
  })
  // Only do this if the layer is visible
  .filter(function (action) {
    return store.getState().map.layers.radar && store.getState().map.playback.timestamp;
  }).map(function (action) {
    return action.type == _actions.SET_TIMESTAMP ? action : (0, _assign["default"])({}, action, {
      payload: store.getState().map.playback.timestamp
    });
  }).switchMap(function (action) {
    return (0, _api["default"])(store).get("maps/radar.json?from=".concat((0, _utilities.formatMoment)(action.payload.clone().subtract(1, 'hour')), "&to=").concat((0, _utilities.formatMoment)(action.payload.clone().add(1, 'hour')))).map(function (result) {
      return result.response;
    }).map(function (radarList) {
      return actions.setRadarWithTimestamp({
        list: radarList,
        timestamp: action.payload
      });
    })["catch"](function (error) {
      console.log(error);
      return _rxjs.Observable.of({}).ignoreElements();
    }).startWith(actions.setRadarLoading());
  });
};
var epic = (0, _reduxObservable.combineEpics)(loadCurrentEpic, loadTimestampEpic);
exports.epic = epic;