"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Vector = _interopRequireDefault(require("ol/layer/Vector"));
var _Feature = _interopRequireDefault(require("ol/Feature"));
var _Point = _interopRequireDefault(require("ol/geom/Point.js"));
var _Projection = _interopRequireDefault(require("ol/proj/Projection.js"));
var _Vector2 = _interopRequireDefault(require("ol/source/Vector.js"));
var _Style = _interopRequireDefault(require("ol/style/Style.js"));
var _Icon = _interopRequireDefault(require("ol/style/Icon.js"));
var _Text = _interopRequireDefault(require("ol/style/Text.js"));
var _Fill = _interopRequireDefault(require("ol/style/Fill.js"));
var _Stroke = _interopRequireDefault(require("ol/style/Stroke.js"));
var _GeoJSON = _interopRequireDefault(require("ol/format/GeoJSON.js"));
var _map = require("../map");
var _constants = require("../../../lib/constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
//import ol, { style } from "openlayers";

// purple commuter rail line
var defaultLineStyle = new _Style["default"]({
  stroke: new _Stroke["default"]({
    color: '#b02a8a',
    width: 3
  }),
  opacity: 1,
  zIndex: _constants.TRAIN_ROUTE_Z_LEVEL,
  text: new _Text["default"]({
    text: '',
    font: '15px sans-serif',
    placement: 'line',
    maxAngle: 0.0872665,
    offsetX: 15,
    offsetY: 15,
    fill: new _Fill["default"]({
      color: '#b02a8a'
    })
  })
});

// silver bus line
var secondaryLineStyle = new _Style["default"]({
  stroke: new _Stroke["default"]({
    color: '#b5b5b5',
    width: 3
  }),
  opacity: 1,
  zIndex: _constants.BUS_ROUTE_Z_LEVEL,
  text: new _Text["default"]({
    text: '',
    font: '15px sans-serif',
    placement: 'line',
    maxAngle: 0.0872665,
    offsetX: 15,
    offsetY: 15,
    fill: new _Fill["default"]({
      color: '#6a3fba'
    })
  })
});
var layer = new _Vector["default"]({
  visible: true,
  declutter: true,
  zIndex: _constants.TRAIN_ROUTE_Z_LEVEL,
  name: "lines",
  updateWhileAnimating: true,
  updateWhileInteracting: true,
  style: function style(feature) {
    var _feature$getPropertie = feature.getProperties(),
      routeType = _feature$getPropertie.route_type;
    var currentZoom = _map.map.getView().getZoom();
    var style;
    switch (routeType) {
      case '3':
        style = secondaryLineStyle;
        break;
      default:
        style = defaultLineStyle;
    }
    if (currentZoom >= _constants.LINE_LABEL_ZOOM) {
      var _routeType = feature.get('route_type');
      var shortName = feature.get('route_short_name');
      var longName = feature.get('route_long_name');
      if (parseInt(_routeType) !== 2) {
        // this one represents a shuttle bus and needs the shortName : longName
        style.getText().setText("".concat(shortName, ": ").concat(longName));
      }
    } else {
      // this needs to be in here to reset the text to nothing if you zoom too far out
      style.getText().setText('');
    }
    return style;
  }
});
_map.map.addLayer(layer);
var lines = {
  handleUpdate: function handleUpdate(_ref) {
    var layers = _ref.layers,
      linesGeoJson = _ref.lines;
    // uset to be layers.lines
    //layer.setVisible(true);
    if (!linesGeoJson || !linesGeoJson.type) return;
    var features = new _GeoJSON["default"]({
      featureProjection: 'EPSG:3857'
    }).readFeatures(linesGeoJson);
    layer.setSource(new _Vector2["default"]({
      features: features
    }));
  }
};
var _default = lines;
exports["default"] = _default;