"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.view = exports.overlay = exports.mapTilerAPIKey = exports.map = exports.centerCoordinates = exports.MAX_ZOOM = exports.DEFAULT_ZOOM = void 0;
var _Map = _interopRequireDefault(require("ol/Map.js"));
var _Overlay = _interopRequireDefault(require("ol/Overlay.js"));
var _Interaction = _interopRequireDefault(require("ol/interaction/Interaction.js"));
var _Control = _interopRequireDefault(require("ol/control/Control.js"));
var _source = _interopRequireDefault(require("ol/source"));
var _TileJSON = _interopRequireDefault(require("ol/source/TileJSON"));
var _Vector = _interopRequireDefault(require("ol/layer/Vector.js"));
var _Vector2 = _interopRequireDefault(require("ol/source/Vector.js"));
var _JSONFeature = _interopRequireDefault(require("ol/format/JSONFeature"));
var _VectorTile = _interopRequireDefault(require("ol/layer/VectorTile.js"));
var _VectorTile2 = _interopRequireDefault(require("ol/source/VectorTile.js"));
var _MVT = _interopRequireDefault(require("ol/format/MVT"));
var _GeoJSON = _interopRequireDefault(require("ol/format/GeoJSON.js"));
var _View = _interopRequireDefault(require("ol/View.js"));
var _OSM = _interopRequireDefault(require("ol/source/OSM.js"));
var _Tile = _interopRequireDefault(require("ol/layer/Tile.js"));
var _Projection = _interopRequireDefault(require("ol/proj/Projection.js"));
var _proj = require("ol/proj");
var _defaults = require("ol/interaction/defaults");
var _PinchRotate = _interopRequireDefault(require("ol/interaction/PinchRotate"));
var _PinchZoom = _interopRequireDefault(require("ol/interaction/PinchZoom"));
var _react = _interopRequireWildcard(require("react"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// import olms, {applyStyle} from 'ol-mapbox-style'

var interactionSettings = (0, _defaults.defaults)({
  pinchRotate: false
});
var mapTilerAPIKey = 'OjLiPCJsTZqegccZym5u';
exports.mapTilerAPIKey = mapTilerAPIKey;
var centerCoordinates = (0, _proj.transform)([-71.1, 42.3], "EPSG:4326", "EPSG:900913");
// denny code for using new map tile library:

// const source = new ource.TileJSON({
//   url: `https://api.maptiler.com/maps/streets-v2/tiles.json?key=${mapTilerAPIKey}`, // source URL
//   tileSize: 512,
//   crossOrigin: 'anonymous'
// });

// const tileLayer = new TileLayer({
//   source: source
// })
// export const [layer, setLayer] = useState('yes')
//console.log('olms? ', olms.apply)
//console.log('mvt? ', MVT)
//console.log('apply style? ', applyStyle)
exports.centerCoordinates = centerCoordinates;
var streetsURL = "https://api.maptiler.com/maps/streets-v2/tiles.json?key=".concat(mapTilerAPIKey);
var satelliteURL = "https://api.maptiler.com/maps/satellite/tiles.json?key=".concat(mapTilerAPIKey);
var basicURL = "https://api.maptiler.com/maps/basic-v2/tiles.json?key=".concat(mapTilerAPIKey);
var winterURL = "https://api.maptiler.com/maps/winter-v2/tiles.json?key=".concat(mapTilerAPIKey);
var topographicURL = "https://api.maptiler.com/maps/topo-v2/tiles.json?key=".concat(mapTilerAPIKey);
var dataVizURL = "https://api.maptiler.com/maps/dataviz/tiles.json?key=".concat(mapTilerAPIKey);
var backdropURL = "https://api.maptiler.com/maps/backdrop/tiles.json?key=".concat(mapTilerAPIKey);
var customURL = "https://api.maptiler.com/maps/6ea2f294-0207-4ddd-9689-db566c8615ed/style.json?key=".concat(mapTilerAPIKey);
var testURL = "https://api.maptiler.com/maps/6ea2f294-0207-4ddd-9689-db566c8615ed/style.json?key=".concat(mapTilerAPIKey);
var basicVectorURL = "https://api.maptiler.com/maps/streets/style.json?key=".concat(mapTilerAPIKey);

// const vectorLayer = new VectorTileLayer({
//   declutter: true,
//   source: new VectorTileSource({
//     format: new MVT(),
//     url: basicVectorURL
//   })
// })

// const test = new VectorLayer({declutter: true})
//applyStyle(vectorLayer, basicVectorURL)

// const vectorLayer = new VectorLayer({
//   source: new VectorSource({
//     url: `https://api.maptiler.com/maps/basic-v2/style.json?key=${mapTilerAPIKey}`,
//     format: new GeoJSON()
//   })
// })

// const tileLayer = new TileLayer({
//   // source: new OSM(),

//   source: new TileJSON({
//     // crossOrigin: null,
//     url: customURL,
//     tileSize: 512,
//     crossOrigin: 'anonymous'
//   })

// });

// end new code
// initialize the tiles
var tileLayer = new _Tile["default"]({
  source: new _OSM["default"]({
    crossOrigin: null,
    url: "https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
  })
});

// Decrease tile layer opacity because the strong colors make it harder to distinguish
// features in the other layers we draw on the map
// tileLayer.setOpacity(0.65);
// vectorLayer.setOpacity(0.65)
var DEFAULT_ZOOM = 9;
exports.DEFAULT_ZOOM = DEFAULT_ZOOM;
var MAX_ZOOM = 19;
//initialize view
exports.MAX_ZOOM = MAX_ZOOM;
var view = new _View["default"]({
  center: centerCoordinates,
  zoom: DEFAULT_ZOOM,
  minZoom: DEFAULT_ZOOM,
  maxZoom: MAX_ZOOM
});

// init map
exports.view = view;
var map = new _Map["default"]({
  layers: [tileLayer],
  //layers: [tileLayer],
  // controls: Control.defaults({
  //   attributionOptions:({
  //     collapsible: false
  //   })
  // }),
  interactions: interactionSettings,
  declutter: false,
  //interactions: new Interaction.defaults({altShiftDragRotate:false, pinchRotate:false,}),
  target: 'map',
  view: view
});
// olms.apply(map, basicVectorURL)

// popover
exports.map = map;
var overlay = new _Overlay["default"]({
  positioning: 'top-left',
  stopEvent: true
});
exports.overlay = overlay;