"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFeaturesFromObject = void 0;
var _find = _interopRequireDefault(require("lodash/find"));
var _maxBy = _interopRequireDefault(require("lodash/maxBy"));
var _Vector = _interopRequireDefault(require("ol/layer/Vector.js"));
var _Feature = _interopRequireDefault(require("ol/Feature.js"));
var _Point = _interopRequireDefault(require("ol/geom/Point.js"));
var _Projection = _interopRequireDefault(require("ol/proj/Projection.js"));
var _proj = require("ol/proj");
var _Vector2 = _interopRequireDefault(require("ol/source/Vector.js"));
var _Style = _interopRequireDefault(require("ol/style/Style.js"));
var _Icon = _interopRequireDefault(require("ol/style/Icon.js"));
var _Text = _interopRequireDefault(require("ol/style/Text.js"));
var _Fill = _interopRequireDefault(require("ol/style/Fill.js"));
var _Stroke = _interopRequireDefault(require("ol/style/Stroke.js"));
var _GeoJSON = _interopRequireDefault(require("ol/format/GeoJSON.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// import ol from "openlayers";

var getFeaturesFromObject = function getFeaturesFromObject(objectWithLocation, collections) {
  var defaultResult = [];
  if (!objectWithLocation) {
    return defaultResult;
  }
  var feature = null;
  var location = getLocation(objectWithLocation, collections.milepostGroups);
  if (!location) return defaultResult;
  var entity;
  if (location.locationTypeId == 2 || location.locationTypeId == 1) {
    // Facilities
    var locationId = location.locationId;
    entity = (0, _find["default"])(collections.locations, {
      locationId: locationId
    });
  } else if (location.locationTypeId == 3) {
    // Lines
    var collection = location.maxLocation.isStation ? collections.stations : collections.mileposts;

    //get feature of the max location (only showing this one due to performance issues)
    var milepostId = location.maxLocation.milepostId;
    entity = (0, _find["default"])(collection, {
      milepostId: milepostId
    });
  }
  if (entity) {
    feature = new _Feature["default"]({
      geometry: new _Point["default"]((0, _proj.transform)([entity.latLong[1], entity.latLong[0]], "EPSG:4326", "EPSG:900913"))
    });
  }
  return feature ? [feature] : defaultResult;
};
exports.getFeaturesFromObject = getFeaturesFromObject;
var getLocation = function getLocation(obj, milepostGroups) {
  if (!obj.location) {
    return null;
  }
  var locationId = obj.location.locationId;
  var locationTypeId = obj.location.locationTypeId;
  var location = {
    locationId: locationId,
    locationTypeId: locationTypeId
  };
  var mpStart = obj.milepost ? parseFloat(obj.milepost.milepost) : null;
  var mpEnd = obj.milepost && obj.milepostEnd ? parseFloat(obj.milepostEnd.milepost) : null;
  var milepostEnd = mpEnd;
  if (mpStart) {
    if (mpStart && !mpEnd) {
      milepostEnd = mpStart;
    } else if (mpStart > mpEnd) {
      milepostEnd = mpStart;
    }
  }
  if (locationTypeId == 3) {
    //Due to performance issues, we're only mapping one feature at the highest mileposts for whole lines
    //if it's a range, just grab the last milepost
    if (milepostEnd) {
      location.maxLocation = (0, _find["default"])(milepostGroups[location.locationId], {
        milepost: milepostEnd
      });
    } else {
      //if not, grab the biggest on the line
      location.maxLocation = (0, _maxBy["default"])(milepostGroups[location.locationId], 'milepost');
    }
  }
  return location;
};