"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zoomLevels = exports.TRAIN_Z_LEVEL = exports.TRAIN_STOP_Z_LEVEL = exports.TRAIN_STOP_ZOOM = exports.TRAIN_ROUTE_Z_LEVEL = exports.STATION_LAYER_Z_LEVEL = exports.STATION_LABEL_ZOOM = exports.PARKING_LOT_Z_LEVEL = exports.PARKING_LOT_ZOOM = exports.LINE_LABEL_ZOOM = exports.INTERLOCKING_Z_LEVEL = exports.INTERLOCKING_ZOOM = exports.CROSSINGS_Z_LEVEL = exports.CROSSINGS_ZOOM = exports.BUS_STOP_Z_LEVEL = exports.BUS_STOP_ZOOM = exports.BUS_ROUTE_Z_LEVEL = void 0;
// Icon and render thresholds
var LINE_LABEL_ZOOM = 9.99;
exports.LINE_LABEL_ZOOM = LINE_LABEL_ZOOM;
var TRAIN_STOP_ZOOM = 11.49;
exports.TRAIN_STOP_ZOOM = TRAIN_STOP_ZOOM;
var INTERLOCKING_ZOOM = 12.99;
exports.INTERLOCKING_ZOOM = INTERLOCKING_ZOOM;
var BUS_STOP_ZOOM = 16.25;
exports.BUS_STOP_ZOOM = BUS_STOP_ZOOM;
var PARKING_LOT_ZOOM = 16.25;
exports.PARKING_LOT_ZOOM = PARKING_LOT_ZOOM;
var CROSSINGS_ZOOM = 16.25;
exports.CROSSINGS_ZOOM = CROSSINGS_ZOOM;
var STATION_LABEL_ZOOM = 17.99;

// layer z level constants
exports.STATION_LABEL_ZOOM = STATION_LABEL_ZOOM;
var TRAIN_Z_LEVEL = 100;
exports.TRAIN_Z_LEVEL = TRAIN_Z_LEVEL;
var TRAIN_STOP_Z_LEVEL = 99;
exports.TRAIN_STOP_Z_LEVEL = TRAIN_STOP_Z_LEVEL;
var BUS_STOP_Z_LEVEL = 98;
exports.BUS_STOP_Z_LEVEL = BUS_STOP_Z_LEVEL;
var STATION_LAYER_Z_LEVEL = 97;
exports.STATION_LAYER_Z_LEVEL = STATION_LAYER_Z_LEVEL;
var INTERLOCKING_Z_LEVEL = 96;
exports.INTERLOCKING_Z_LEVEL = INTERLOCKING_Z_LEVEL;
var CROSSINGS_Z_LEVEL = 96;
exports.CROSSINGS_Z_LEVEL = CROSSINGS_Z_LEVEL;
var TRAIN_ROUTE_Z_LEVEL = 95;
exports.TRAIN_ROUTE_Z_LEVEL = TRAIN_ROUTE_Z_LEVEL;
var BUS_ROUTE_Z_LEVEL = 94;
exports.BUS_ROUTE_Z_LEVEL = BUS_ROUTE_Z_LEVEL;
var PARKING_LOT_Z_LEVEL = 93;
exports.PARKING_LOT_Z_LEVEL = PARKING_LOT_Z_LEVEL;
var zoomLevels = {
  LINE_LABEL_ZOOM: LINE_LABEL_ZOOM,
  TRAIN_STOP_ZOOM: TRAIN_STOP_ZOOM,
  INTERLOCKING_ZOOM: INTERLOCKING_ZOOM,
  BUS_STOP_ZOOM: BUS_STOP_ZOOM,
  PARKING_LOT_ZOOM: PARKING_LOT_ZOOM,
  STATION_LABEL_ZOOM: STATION_LABEL_ZOOM,
  CROSSINGS_ZOOM: CROSSINGS_ZOOM
};
exports.zoomLevels = zoomLevels;