"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = void 0;
var _rxjs = require("rxjs");
var _includes = _interopRequireDefault(require("lodash/includes"));
var _store = _interopRequireDefault(require("../../../store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// EPIC
var epic = function epic(action$, store) {
  return action$.filter(function (a) {
    return (0, _includes["default"])([_store["default"].TOGGLE_LAYER, _store["default"].TOGGLE_ALL, _store["default"].SET_LAYERS], a.type);
  })["do"]()["do"](function (action) {
    // State State to AsyncStorage
    var state = store.getState().map.layers;
    try {
      localStorage.setItem('map-filter-prod', JSON.stringify(state));
    } catch (error) {
      console.log("Error saving layers", error);
    }
  }).ignoreElements();
};
exports.epic = epic;