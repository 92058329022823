"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectRouteDivisions = exports.selectMilepostsGroupedByLocationId = exports.selectIsLoading = exports.selectCommuteSource = exports.mapSelector = void 0;
var _reselect = require("reselect");
var _some = _interopRequireDefault(require("lodash/some"));
var _keyBy = _interopRequireDefault(require("lodash/keyBy"));
var _groupBy = _interopRequireDefault(require("lodash/groupBy"));
var _fromPairs = _interopRequireDefault(require("lodash/fromPairs"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// import { playbackLayers } from './playback';

var mapSelector = function mapSelector(state) {
  return state.map;
};
exports.mapSelector = mapSelector;
var selectIsLoading = (0, _reselect.createSelector)(mapSelector, function (map) {
  var activePlaybackLayers = playbackLayers.filter(function (l) {
    return map.layers[l];
  }).map(function (layerName) {
    return map[layerName];
  });
  return (0, _some["default"])(activePlaybackLayers, function (l) {
    return l === null || l === void 0 ? void 0 : l.isLoading;
  });
});

// export const selectStations = createSelector(mapSelector, map => {
//     return map.stations.filter(s => s.x && s.y);
// });

// export const selectStationsById = createSelector(selectStations, stations => {
//     return keyBy(stations, 'stationId');
// });

// export const selectStationsByCode = createSelector(selectStations, stations => {
//     return keyBy(stations, 'code');
// });
exports.selectIsLoading = selectIsLoading;
var selectMilepostsGroupedByLocationId = (0, _reselect.createSelector)(mapSelector, function (map) {
  return (0, _groupBy["default"])(map.mileposts, 'locationId');
});
exports.selectMilepostsGroupedByLocationId = selectMilepostsGroupedByLocationId;
var selectCommuteSource = (0, _reselect.createSelector)(mapSelector, function (map) {
  var _map$options = map.options,
    useDefaultCommuteSource = _map$options.useDefaultCommuteSource,
    commuteWsUrl = _map$options.commuteWsUrl,
    commuteApiUrl = _map$options.commuteApiUrl,
    altCommuteWsUrl = _map$options.altCommuteWsUrl,
    altCommuteApiUrl = _map$options.altCommuteApiUrl;
  return {
    ws: useDefaultCommuteSource ? commuteWsUrl : altCommuteWsUrl,
    api: useDefaultCommuteSource ? commuteApiUrl : altCommuteApiUrl
  };
});
exports.selectCommuteSource = selectCommuteSource;
var selectRouteDivisions = (0, _reselect.createSelector)(mapSelector, function (map) {
  var routeDivisionPairs = map.locations.filter(function (loc) {
    return loc.route && loc.route.trim();
  }).map(function (_ref) {
    var route = _ref.route,
      division = _ref.division;
    return [route, division.name.toLowerCase()];
  });
  return (0, _fromPairs["default"])(routeDivisionPairs);
});
exports.selectRouteDivisions = selectRouteDivisions;