import React, { useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCompass,
  faSubway,
  faSearch,
  // faLayerGroup,
  faBullhorn,
  faInfoCircle,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';

function MobileNavMenu(props) {
  const {
    openTrainListDrawer,
    toggleTrainListDrawer,
    // openLayerModal,
    // toggleLayerModal,
    openLegendDrawer,
    toggleLegendDrawer,
    openSearchDrawer,
    toggleSearchDrawer,
    openInfoDrawer,
    toggleInfoDrawer,
    openAllPageDrawer,
    toggleAllPageDrawer,
    isOffline,
  } = props;

  const legendButtonRef = useRef(null);
  // const layerButtonRef = useRef(null);
  const trainsListButtonRef = useRef(null);
  const searchButtonRef = useRef(null);
  const infoButtonRef = useRef(null);
  const allPageButtonRef = useRef(null);

  return (
    <Navbar
      fixed="bottom"
      className="d-lg-none justify-content-around px-3 pt-1 pb-4"
      style={{ backgroundColor: '#786e64', zIndex: 1400 }}
    >
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Info</Tooltip>}>
        <Button
          ref={infoButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          active={openInfoDrawer}
          onClick={() => {
            if (infoButtonRef.current) {
              infoButtonRef.current.blur();
            }
            toggleInfoDrawer();
          }}
          size="md"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Legend</Tooltip>}>
        <Button
          ref={legendButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          size="md"
          active={openLegendDrawer}
          onClick={() => {
            toggleLegendDrawer();
            if (legendButtonRef.current) {
              legendButtonRef.current.blur();
            }
          }}
        >
          <FontAwesomeIcon icon={faCompass} />
        </Button>
      </OverlayTrigger>
      {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Layers</Tooltip>}>
        <Button
          ref={layerButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          size="md"
          active={openLayerModal}
          onClick={() => {
            if (layerButtonRef.current) {
              layerButtonRef.current.blur();
            }
            toggleLayerModal();
          }}
        >
          <FontAwesomeIcon icon={faLayerGroup} />
        </Button>
      </OverlayTrigger> */}
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Train List</Tooltip>}>
        <Button
          disabled={isOffline}
          ref={trainsListButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          size="md"
          active={openTrainListDrawer}
          onClick={() => {
            if (trainsListButtonRef.current) {
              trainsListButtonRef.current.blur();
            }
            toggleTrainListDrawer();
          }}
        >
          <FontAwesomeIcon icon={isOffline ? faWifi : faSubway} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">All Page</Tooltip>}>
        <Button
          ref={allPageButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          size="md"
          active={openAllPageDrawer}
          onClick={() => {
            if (allPageButtonRef.current) {
              allPageButtonRef.current.blur();
            }
            toggleAllPageDrawer();
          }}
        >
          <FontAwesomeIcon icon={faBullhorn} />
        </Button>
      </OverlayTrigger>
      {/* <OverlayTrigger
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
            Manifest
            </Tooltip>
          }
        >
          <Button 
            ref={trainDrawerRef}
            className="m-1 w-25" 
            variant="outline-light" 
            size="md" 
            active={openTrainDrawer}
            onClick={() => {
              if (trainDrawerRef.current) {
                trainDrawerRef.current.blur()
              }
              toggleTrainDrawer()
            }}
          >
            <FontAwesomeIcon icon={faClock}/>
          </Button>
        </OverlayTrigger> */}
      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Search</Tooltip>}>
        <Button
          ref={searchButtonRef}
          className="m-1 w-25"
          variant="outline-light"
          active={openSearchDrawer}
          onClick={() => {
            if (searchButtonRef.current) {
              searchButtonRef.current.blur();
            }
            toggleSearchDrawer();
          }}
          size="md"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </OverlayTrigger>
    </Navbar>
  );
}

export default MobileNavMenu;
