"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _FraminghamWorcester;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = (_FraminghamWorcester = {
  'Framingham/Worcester': 'Worcester Main Line',
  'Providence': 'Providence',
  'Western Start': 'Western Route Main Line',
  'Fitchburg Start': 'Boston North Terminal',
  'Providence to Wickford': 'Attleboro Line'
}, _defineProperty(_FraminghamWorcester, "Providence", 'Attleboro Line'), _defineProperty(_FraminghamWorcester, 'Middleboro Start', "Middleboro Main Line"), _FraminghamWorcester);
exports["default"] = _default;