"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _reduxObservable = require("redux-observable");
var _api = _interopRequireDefault(require("../../api"));
var _utilities = require("../../utilities");
var _actions = require("../actions");
var _layers = require("../layers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// ACTIONS
var actionTypes = {
  LOAD_EQUIPMENT_RANGE: 'KEOLIS-MAP/LOAD_EQUIPMENT_RANGE',
  ADD_TO_EQUIPMENT_CACHE: 'KEOLIS-MAP/ADD_TO_EQUIPMENT_CACHE'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  loadEquipmentRange: function loadEquipmentRange(payload) {
    return {
      type: actionTypes.LOAD_EQUIPMENT_RANGE,
      payload: payload
    };
  },
  addToEquipmentCache: function addToEquipmentCache(payload) {
    return {
      type: actionTypes.ADD_TO_EQUIPMENT_CACHE,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.ADD_TO_EQUIPMENT_CACHE:
      var _action$payload = action.payload,
        list = _action$payload.list,
        timestamp = _action$payload.timestamp;
      return (0, _assign["default"])({}, state, {
        cache: (0, _utilities.reduceEquipmentList)(state.cache, list),
        isLoading: false,
        maxLoadedDate: timestamp,
        isBuffering: false
      });
    case actionTypes.LOAD_EQUIPMENT_RANGE:
      return (0, _assign["default"])({}, state, {
        isBuffering: true
      });
    case _actions.TICK:
      // checks the layer to see if it should be loading
      var nextTimestamp = action.payload;
      // check if we haven't loaded any playback data yet
      // otherwise - set back to not loading
      return (0, _assign["default"])({}, state, {
        isLoading: !state.maxLoadedDate || nextTimestamp.isSameOrAfter(state.maxLoadedDate)
      });
    case _actions.RESOLVE_TICK:
      // ok - here's where we actually update the list of trains
      var nextCache = (0, _utilities.clearCache)(state.cache, action.payload, 1000 * 60 * 60);
      return (0, _assign["default"])({}, state, {
        cache: nextCache,
        list: (0, _utilities.getListForTimestamp)(nextCache, action.payload)
      });
    default:
      return state;
  }
}

// EPICS

var leadTimeBase = 5000;
var maxLeadTime = 1000 * 60 * 60 * 2;
var bufferingEpic = function bufferingEpic(action$, store) {
  return action$.ofType(_actions.TICK).filter(function (action) {
    var timestamp = action.payload;
    var _store$getState$map = store.getState().map,
      equipment = _store$getState$map.equipment,
      playback = _store$getState$map.playback,
      layers = _store$getState$map.layers;
    var _getPlaybackTimingFro = (0, _utilities.getPlaybackTimingFromSpeed)(playback.speed),
      tick = _getPlaybackTimingFro.tick,
      modifier = _getPlaybackTimingFro.modifier;
    var leadTime = Math.min(leadTimeBase * tick, maxLeadTime);
    return layers.equipment && !equipment.isBuffering && (0, _utilities.checkIfBufferingDataNeeded)(playback.speed, leadTime / 2, timestamp, equipment.maxLoadedDate);
  }).map(function (action) {
    var _store$getState$map2 = store.getState().map,
      equipment = _store$getState$map2.equipment,
      playback = _store$getState$map2.playback;
    var _getPlaybackTimingFro2 = (0, _utilities.getPlaybackTimingFromSpeed)(playback.speed),
      tick = _getPlaybackTimingFro2.tick,
      modifier = _getPlaybackTimingFro2.modifier;
    var leadTime = Math.min(leadTimeBase * tick, maxLeadTime);
    var startPoint = equipment.maxLoadedDate ? equipment.maxLoadedDate : action.payload.clone().subtract(1, 'hour');
    return actions.loadEquipmentRange({
      start: startPoint,
      end: startPoint.clone().add(leadTime, 'ms')
    });
  });
};
var loadBufferingEpic = function loadBufferingEpic(action$, store) {
  return action$.ofType(actionTypes.LOAD_EQUIPMENT_RANGE).switchMap(function (action) {
    var _action$payload2 = action.payload,
      start = _action$payload2.start,
      end = _action$payload2.end;
    return (0, _api["default"])(store).get("system/equipment/locations.json?start=".concat((0, _utilities.formatMoment)(start), "&end=").concat((0, _utilities.formatMoment)(end))).map(function (result) {
      return result.response;
    }).map(function (items) {
      return actions.addToEquipmentCache({
        list: items,
        timestamp: end
      });
    })["catch"](function (error) {
      console.log(error);
      return _rxjs.Observable.of({}).ignoreElements();
    });
  });
};
var epic = (0, _reduxObservable.combineEpics)(bufferingEpic, loadBufferingEpic);
exports.epic = epic;