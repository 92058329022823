// Libraries
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

// Map
import keolisMap from '@bowst/keolis-map-component/dist/store';
import webMapEpic from '@bowst/keolis-map-component/dist/desktop/epics';

const list = [];

// EPICS
const epics = list
  .filter((l) => l.epic)
  .map((l) => l.epic)
  .concat([keolisMap.epic, webMapEpic]);
const rootEpic = combineEpics(...epics);

const epicMiddleware = createEpicMiddleware(rootEpic);

/*
 * STORE CREATION
 */

// Dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  // APP Reducers
  map: keolisMap.createReducerWithOptions({
    commuteWsUrl: process.env.REACT_APP_DEFAULT_COMMUTE_URL,
    commuteApiUrl: process.env.REACT_APP_DEFAULT_COMMUTE_API_URL,
    keolisApiUrl: process.env.REACT_APP_KEOLIS_API_URL,
  }),
  authDEPRECATED: () => ({
    token:
      'eyJhbGciOiJIUzI1NiIsImlhdCI6MTYyNjM3NTE5NywiZXhwIjoxNjI2NDYxNTk3fQ.eyJwYWRkaW5nIjoickxmUHYzcU9KdUFxekhIMGQzU1prVzJlSnZvUURTM2VoUGlnbFdjZUdEWjlBMms4M1J3WTVDS2l1eG0yWTFMa3IvSkpQSXhjVTRCek5WaHR3VjNKZU1HalFZWkdrQzdKaXNnaWQyZm1CWmdGcjZqUGQ3dU43UHJtQmJTUW1VNEt4eTZXdXc9PSIsImlkIjozNjMyfQ.4-T70gAeNJCXrm9hE9Rx33sLzACIrF6_32ibTzvQCwI',
  }),
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)));

export default store;
