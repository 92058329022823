"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.actionTypes = exports.TICK = exports.STOP_POLLING = exports.STOP_PLAYBACK = exports.START_POLLING = exports.START_PLAYBACK = exports.SET_TIMESTAMP = exports.RESOLVE_TICK = exports.LOADING_COMPLETE = exports.INIT = void 0;
var _reduxObservable = require("redux-observable");
var INIT = 'KEOLIS-MAP/INIT';
exports.INIT = INIT;
var START_POLLING = 'KEOLIS-MAP/START_POLLING';
exports.START_POLLING = START_POLLING;
var STOP_POLLING = 'KEOLIS-MAP/STOP_POLLING';
exports.STOP_POLLING = STOP_POLLING;
var START_PLAYBACK = 'KEOLIS-MAP/START_PLAYBACK';
exports.START_PLAYBACK = START_PLAYBACK;
var STOP_PLAYBACK = 'KEOLIS-MAP/STOP_PLAYBACK';
exports.STOP_PLAYBACK = STOP_PLAYBACK;
var SET_TIMESTAMP = 'KEOLIS-MAP/SET_TIMESTAMP';
exports.SET_TIMESTAMP = SET_TIMESTAMP;
var TICK = 'KEOLIS-MAP/TICK';
exports.TICK = TICK;
var RESOLVE_TICK = 'KEOLIS-MAP/RESOLVE_TICK';
exports.RESOLVE_TICK = RESOLVE_TICK;
var LOADING_COMPLETE = 'KEOLIS-MAP/LOADING_COMPLETE';

// ACTIONS
exports.LOADING_COMPLETE = LOADING_COMPLETE;
var actionTypes = {
  INIT: INIT,
  START_POLLING: START_POLLING,
  STOP_POLLING: STOP_POLLING,
  START_PLAYBACK: START_PLAYBACK,
  STOP_PLAYBACK: STOP_PLAYBACK,
  SET_TIMESTAMP: SET_TIMESTAMP,
  TICK: TICK,
  RESOLVE_TICK: RESOLVE_TICK,
  LOADING_COMPLETE: LOADING_COMPLETE
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  init: function init(payload) {
    return {
      type: INIT,
      payload: payload
    };
  },
  startPolling: function startPolling(payload) {
    return {
      type: START_POLLING,
      payload: payload
    };
  },
  stopPolling: function stopPolling(payload) {
    return {
      type: STOP_POLLING,
      payload: payload
    };
  },
  startPlayback: function startPlayback(payload) {
    return {
      type: START_PLAYBACK,
      payload: payload
    };
  },
  stopPlayback: function stopPlayback(payload) {
    return {
      type: STOP_PLAYBACK,
      payload: payload
    };
  },
  setTimestamp: function setTimestamp(payload) {
    return {
      type: SET_TIMESTAMP,
      payload: payload
    };
  },
  tick: function tick(payload) {
    return {
      type: TICK,
      payload: payload
    };
  },
  resolveTick: function resolveTick(payload) {
    return {
      type: RESOLVE_TICK,
      payload: payload
    };
  },
  loadComplete: function loadComplete(payload) {
    return {
      type: LOADING_COMPLETE,
      payload: payload
    };
  }
};
exports.actions = actions;