/*eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Resizable from "react-resizable-box";
import moment from "moment";
import { Button, Spinner } from "react-bootstrap";

import KeolisMap from "@bowst/keolis-map-component/dist/desktop/map/map";
import TrainList from "@bowst/keolis-map-component/dist/desktop/train-list/train-list";
import TrainDrawer from "@bowst/keolis-map-component/dist/desktop/map/drawers/TrainDrawer";
import StationDrawer from "@bowst/keolis-map-component/dist/desktop/map/drawers/StationDrawer";
import "@bowst/keolis-map-component/css/styles.css";
import { useMapState } from "@bowst/keolis-map-component/dist/desktop/hooks/mapState";

import Drawer from "@material-ui/core/Drawer";
import { AllPage } from "./all-page/index";

import MobileNavMenu from "./MobileNavMenu";
import MobileLegend from "./MobileLegend";
import Search from "./Search/index";
import MagicBellSubscription from "./MagicBellSubscription";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";

import "./App.css";
import { isTokenValid, trackMessage } from "./utils";

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    console.log("in set interval", callback, delay);
    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);
}

const getPermissionsFromAccessToken = (token) => {
  const parseJwt = (jwt) => {
    const base64Payload = jwt.split('.')[1];
    const payload = atob(base64Payload);
    return JSON.parse(payload);
  };

  let canTag = false;

  if (token) {
    const { groups } = parseJwt(token);

    // KvlVehicleAssignmentEditor AD group: eb180cb1-c606-4869-9b0b-1046b0a4301b
    // BOWST VPN AD group: bfdf5be3-e178-4043-884a-a2acd0c292e6

    if (groups && (groups.includes('eb180cb1-c606-4869-9b0b-1046b0a4301b') || groups.includes('bfdf5be3-e178-4043-884a-a2acd0c292e6'))) {
      canTag = true;
    }
  }

  return {
    canTag,
  };
};

function Home(props) {
  const { notificationUrl, setNotificationUrl } = props;
  const { mapState, setAuthToken, setMapAuthToken, setCanTag } = useMapState();
  const { selected } = mapState;
  const [isUserReady, setIsUserReady] = useState(false);
  const [authLastRefreshed, setAuthLastRefreshed] = useState(null);
  const [isOffline, setIsOffline] = useState(false);

  const keolisApiToken = window.localStorage.getItem("keolisApiToken");
  const keolisApiMapLayerToken = window.localStorage.getItem("keolisApiMapLayerToken");

  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const { instance: msalInstance } = useMsal();

  const setMapPermissions = (permissions) => {
    if (permissions) {
      const { canTag } = permissions;
      setCanTag(canTag);
    }
  };

  const refreshAuth = () => {
    if (!navigator.onLine) return;

    const accounts = msalInstance.getAllAccounts();

    const request = {
      scopes: [process.env.REACT_APP_AZURE_AD_TOKEN_SCOPES],
      forceRefresh: true,
    };

    msalInstance.setActiveAccount(accounts[0]);

    const currentToken = window.localStorage.getItem("keolisApiToken");
    if (currentToken && isTokenValid(currentToken)) {
      return;
    }

    msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        const previousToken = window.localStorage.getItem("keolisApiToken");
        const previousAccessToken = window.localStorage.getItem("keolisApiMapLayerToken");
        if (previousToken !== response.idToken || previousAccessToken !== response.accessToken) {
          window.localStorage.setItem("keolisApiToken", response.idToken);
          window.localStorage.setItem("keolisApiMapLayerToken", response.accessToken);

          setAuthLastRefreshed(Date.now());
          setIsUserReady(true);
        }
      })
      .catch(() => {
        // window.localStorage.clear();
        history.replace("/", { forceLogin: true });
      });
  };

  useInterval(refreshAuth, 2000);

  const [openTrainList, setOpenTrainList] = useState(false);
  const [openLegend, setOpenLegend] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openTrainDrawer, setOpenTrainDrawer] = useState(false);
  // eslint-disable-next-line
  const [openStationDrawer, setOpenStationDrawer] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [isForceRefreshing, setIsForceRefreshing] = useState(false);
  const [openAllPageDrawer, setOpenAllPageDrawer] = useState(false);

  const [userId, setUserId] = useState(null);
  const [userHmac, setUserHmac] = useState(null);

  const { openLayerModal, toggleLayerModal } = props;
  const isMobile = window.innerWidth <= 992;

  const setOpenLayerModal = (open) => {
    if (Boolean(open) !== Boolean(openLayerModal)) {
      toggleLayerModal();
    }
  };

  useEffect(() => {
    if (notificationUrl) {
      setOpenAllPageDrawer(true);
    }
  }, [notificationUrl]);

  useEffect(() => {
    if (navigator.onLine && (!isAuthenticated || !keolisApiToken)) {
      console.log("User not authenticated...");
      // window.localStorage.clear();
      history.replace("/", { forceLogin: true });
    } else if (keolisApiToken && isTokenValid(keolisApiToken)) {
      setIsUserReady(true);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_VERSION) {
      trackMessage(`Build version: ${process.env.REACT_APP_VERSION}`);
    }

    if (isUserReady) {
      console.log("User is signed in and ready.");

      setAuthToken(keolisApiToken);
      setMapAuthToken(keolisApiMapLayerToken);
      setMapPermissions(getPermissionsFromAccessToken(keolisApiMapLayerToken));

      const storedUserHmac = localStorage.getItem('userHmac');
      const storedUserId = localStorage.getItem('userId'); 

      if (storedUserHmac) {
        setUserHmac(storedUserHmac);
        trackMessage(`User HMAC: ${storedUserHmac}`);
      }

      if (storedUserId) {
        setUserId(storedUserId);
        trackMessage(`User HMAC: ${storedUserId}`);
      }

      if (!storedUserHmac || !storedUserId) {
        fetch(`${process.env.REACT_APP_KEOLIS_API_URL}users/me.json`, {
          headers: {
            ApplicationID: 'event_manager',
            Authorization: keolisApiToken,
          },
        }).then((response) => {
          if (!response.ok) {
            console.error('Unable to obtain user HMAC and ID');
          } else {
            response.json().then((data) => {
              if (data.email && data.notificationsHmac) {
                setUserId(data.email);
                setUserHmac(data.notificationsHmac);
                localStorage.setItem('userId', data.email);
                localStorage.setItem('userHmac', data.notificationsHmac);
                trackMessage(`Fetched data from me.json API. User ID: ${data.email}, HMAC: ${data.notificationsHmac}`);
              }
            })
          }
        }).catch((e) => {
          console.error(e);
        })
      }

      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((reg) => {
          if (reg.pushManager) {
            reg.pushManager.getSubscription().then((subscription) => {
              if (subscription) {
                trackMessage(`Active subscription from service worker: ${subscription.endpoint}, ${subscription.expirationTime}`);
                console.log('Active subscription', subscription);
              } else {
                trackMessage(`No active subscription found from service worker.`);
                console.log('No active subscription found.');
              }
            })
          }
        }).catch((e) => {
          trackMessage(`Error obtaining service worker registration: ${e?.message}`);
        });
      }

      console.log("User tokens updated in map store.");

      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition((position) => {
      //     if (position.coords) {
      //       const { latitude, longitude } = position.coords;
      //       zoomTo([latitude, longitude])
      //     }
      //   }, (err) => {
      //     console.log(err)
      //   })
      // }
      // eslint-disable-next-line
    }
  }, [isUserReady, authLastRefreshed]);

  const getMapHeight = () => {
    if (openTrainDrawer && isMobile) {
      return "calc(54vh - 49px)";
    }

    return "calc(100vh - 49px)";
  };

  useEffect(() => {
    console.log("in the effect, is mobile: ", isMobile);
    setOpenTrainList(false);
    // this if needs to know if it's a mobile view or not...
    if (selected.type === "train" && isMobile) {
      if (selected.tripShortName) {
        // Only open the train drawer if the train has a trip assignment.
        // This works around the issue where opening the train drawer
        // in mobile prevents user from interacting with the Trip ID
        // text input in the train tooltip (used for train tagging)
        // COMMENTED OUT PER GEORGE FEB 20 2024
        // setOpenTrainDrawer(true);
      }
    }
    if (selected.type === "station" && window.innerWidth <= 992) {
      if (selected?.vehicleType !== "3") {
        // COMMENTED OUT PER GEORGE FEB 20 2024
        // setOpenStationDrawer(true);
      }
    }
    if ((!selected.type || !isMobile) && (openTrainDrawer || openStationDrawer)) {
      // setOpenTrainDrawer(false);
      // setOpenStationDrawer(false);
    }
  }, [selected, isMobile]);

  if (!keolisApiToken) {
    return <div />;
  }

  const touchStartRef = useRef(null);

  const handleTouchStart = (event) => {
    touchStartRef.current = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY,
    }; // Record the initial touch position
  };

  const handleTouchMove = (event) => {
    if (touchStartRef.current !== null) {
      const touchEnd = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
      const touchDiffX = Math.abs(touchEnd.x - touchStartRef.current.x);
      const touchDiffY = Math.abs(touchEnd.y - touchStartRef.current.y);

      if (touchDiffX > touchDiffY) {
        event.preventDefault();
      }

      touchStartRef.current = null;
    }
  };

  window.addEventListener("offline", () => {
    setIsOffline(true);
  });

  window.addEventListener("online", () => {
    setIsOffline(false);
  });

  const standaloneStyle = {}
  if (window.navigator.standalone) {
    standaloneStyle.paddingTop = "40px !important";
  }

  return (
    <div style={{ width: "100%", height: getMapHeight() }} id="kvl-home">
      <div style={{ height: "100%", display: "flex" }} id="main-content">
        {/SamsungBrowser/.test(navigator.userAgent) ? (
          <div style={{ width: "50%", backgroundColor: "#fff", height: "calc(100vh)" }}>{/* <TrainList /> */}</div>
        ) : (
          <Resizable
            className="d-none d-lg-block resizable-trains-list"
            style={{ backgroundColor: "#fff" }}
            width="28%"
            minWidth={400}
            onResizeStop={() => {
              // The map canvas distorts anytime the size of our elements
              // change. The map knows how to resize itself to correct
              // distortion anytime the window resize event occurs, so we'll
              // fire off that event.
              window.dispatchEvent(new Event("resize"));
            }}
          >
            <TrainList />
          </Resizable>
        )}
        {isOffline && (
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            style={{
              position: "fixed",
              top: isMobile ? 0 : "5%",
              right: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#212529",
              opacity: 0.7,
              zIndex: 999,
            }}
          >
            <div
              className="d-flex vstack align-items-center justify-content-center fw-bold fs-2"
              style={{ height: "100vh", color: "white" }}
            >
              <FontAwesomeIcon size="8x" className="pb-4" icon={faWifi} />
              You Are In Offline Mode
            </div>
          </div>
        )}
        <KeolisMap
          allowSystemMap={false}
          allowAltCommuteSource={false}
          includeTasksLayer={false}
          useStationFullNames
          isKVL
          isMobile
          playbackStartDate={moment().subtract(3, "days")}
          onToggleInfo={() => {
            setOpenInfo(!openInfo);
            console.log("opened info");
          }}
          onToggleAllPages={() => {
            setOpenAllPageDrawer(!openAllPageDrawer);
          }}
        />

        {/* {selected?.type === 'train' && selected?.tripShortName && (
          <Resizable
            className="d-none d-lg-block resizable-trains-list"
            style={{ backgroundColor: '#fff' }}
            width="33%"
            minWidth={400}
            onResizeStop={() => {
              // The map canvas distorts anytime the size of our elements
              // change. The map knows how to resize itself to correct
              // distortion anytime the window resize event occurs, so we'll
              // fire off that event.
              window.dispatchEvent(new Event('resize'));
            }}
          >
            <TrainDrawer
              // reduxToken={props?.map?.auth?.token}
              token={mapState?.auth?.token}
            />
          </Resizable>
        )}
        {selected?.type === 'station' && selected?.vehicleType !== '3' && (
          <Resizable
            className="d-none d-lg-block resizable-trains-list"
            style={{ backgroundColor: '#fff' }}
            width="33%"
            minWidth={400}
            onResizeStop={() => {
              // The map canvas distorts anytime the size of our elements
              // change. The map knows how to resize itself to correct
              // distortion anytime the window resize event occurs, so we'll
              // fire off that event.
              window.dispatchEvent(new Event('resize'));
            }}
          >
            <StationDrawer token={mapState?.auth?.token} />
          </Resizable>
        )} */}
      </div>
      <Drawer className="d-lg-none" anchor="left" open={openTrainList} onClose={() => setOpenTrainList(false)}>
        <div style={{ backgroundColor: "#822b6a"}}>
          <div style={{backgroundColor: '#ffffff', marginTop: '60px'}}>
            <TrainList />
          </div>
        </div>
      </Drawer>
      <Drawer
        className="d-lg-none mobile-legend"
        ModalProps={{ hideBackdrop: true }}
        anchor="left"
        open={openLegend}
        onClose={() => setOpenLegend(false)}
        onClick={() => setOpenLegend(false)}
        style={{ opacity: 1 }}
      >
        <MobileLegend />
      </Drawer>
      <Drawer className="d-lg-none" anchor="left" open={openSearch} onClose={() => setOpenSearch(false)}>
        <Search trains={mapState?.trains?.features} closeSearch={() => setOpenSearch(false)} />
      </Drawer>

      <Drawer className="info-drawer" anchor="left" open={openInfo} onClose={() => setOpenInfo(false)}>
        <div className="d-flex align-items-center flex-column justify-content-center h-100">
          <div>
            {process.env.REACT_APP_VERSION && <div>Build version: {process.env.REACT_APP_VERSION.substring(0, 7)}</div>}
          </div>

          <div className="mt-4" style={{ width: "230px" }}>
            <Button
              className="w-100"
              variant="info"
              disabled={isForceRefreshing}
              onClick={() => {
                trackMessage('User requested data refresh');
                setIsForceRefreshing(true);
                if (navigator.serviceWorker) {
                  navigator.serviceWorker.getRegistration().then((registration) => {
                    trackMessage('Obtained service worker registration');
                    if (registration) {
                      registration.update().then(() => {
                        trackMessage('Updated service worker registration');
                        window.location.reload();
                      });
                    } else {
                      trackMessage('No registered service worker found.');
                      setIsForceRefreshing(false);
                      setOpenInfo(false); 
                      window.location.reload();
                    }
                  });
                } else {
                  trackMessage('No service worker on navigator object.');
                  setIsForceRefreshing(false);
                  setOpenInfo(false); 
                  window.location.reload();
                }

                /*
                if (!isAuthenticated) {
                  console.log("User not authenticated...");
                  history.replace("/", { forceLogin: true });
                  return;
                }
                console.log("Refreshing auth token...");
                refreshAuth().finally(() => {
                  setIsForceRefreshing(false);
                  setOpenInfo(false);
                });
                */
              }}
            >
              Refresh Data
              {isForceRefreshing && (
                <span>
                  {"  "}
                  <Spinner as="span" animation="border" role="status" size="sm" aria-hidden="true" />
                </span>
              )}
            </Button>
          </div>
          {userId && userHmac && 
            <MagicBellSubscription
              apiKey={process.env.REACT_APP_MAGICBELL_API_KEY}
              userExternalId={userId}
              userKey={userHmac}
            />
          } 
          <div className="mt-4" style={{ width: "230px" }}>
            <Button className="w-100" variant="outline-info" onClick={() => setOpenInfo(false)}>
              Close
            </Button>
          </div>
        </div>
      </Drawer>

      {/* <Drawer
        className="d-lg mobile-train-drawer"
        anchor="bottom"
        open={openTrainDrawer}
        onClose={() => setOpenTrainDrawer(false)}
      >
        <TrainDrawer token={mapState?.auth?.token} />
      </Drawer>
      <Drawer
        className="d-lg mobile-train-drawer"
        anchor="bottom"
        open={openStationDrawer}
        onClose={() => setOpenStationDrawer(false)}
      >
        <StationDrawer token={mapState?.auth?.token} />
      </Drawer> */}
      <Drawer className="d-lg" anchor="right" Modal={false} open={openAllPageDrawer} onClose={() => setOpenAllPageDrawer(false)}>
        <AllPage
          setOpenAllPageDrawer={setOpenAllPageDrawer}
          notificationUrl={notificationUrl}
          setNotificationUrl={setNotificationUrl}
        />
      </Drawer>
      <MobileNavMenu
        isOffline={isOffline}
        selected={selected}
        openTrainListDrawer={openTrainList}
        toggleTrainListDrawer={() => {
          // close any other open drawers
          setOpenLayerModal(false);
          setOpenLegend(false);
          setOpenSearch(false);
          setOpenTrainDrawer(false);
          setOpenAllPageDrawer(false);

          setOpenTrainList(!openTrainList);
        }}
        openLayerModal={openLayerModal}
        toggleLayerModal={() => {
          // close any other open drawers
          setOpenLegend(false);
          setOpenSearch(false);
          setOpenTrainList(false);
          setOpenTrainDrawer(false);
          setOpenAllPageDrawer(false);

          setOpenLayerModal(!openLayerModal);
        }}
        openLegendDrawer={openLegend}
        toggleLegendDrawer={() => {
          // close any other open drawers
          setOpenLayerModal(false);
          setOpenSearch(false);
          setOpenTrainList(false);
          setOpenTrainDrawer(false);
          setOpenAllPageDrawer(false);

          setOpenLegend(!openLegend);
        }}
        openSearchDrawer={openSearch}
        toggleSearchDrawer={() => {
          // close any other open drawers
          setOpenLayerModal(false);
          setOpenLegend(false);
          setOpenTrainList(false);
          setOpenTrainDrawer(false);
          setOpenAllPageDrawer(false);

          setOpenSearch(!openSearch);
        }}
        openAllPageDrawer={openAllPageDrawer}
        toggleAllPageDrawer={() => {
          setOpenLayerModal(false);
          setOpenSearch(false);
          setOpenTrainList(false);
          setOpenTrainDrawer(false);
          setOpenLegend(false);

          setOpenAllPageDrawer(!openAllPageDrawer);
        }}
        openTrainDrawer={openTrainDrawer}
        toggleTrainDrawer={() => {
          setOpenLayerModal(false);
          setOpenLegend(false);
          setOpenTrainList(false);
          setOpenAllPageDrawer(false);
          // COMMENTED OUT PER GEORGE FEB 20 2024
          // setOpenTrainDrawer(!openTrainDrawer);
          setOpenTrainDrawer(false);
        }}
        openInfoDrawer={openInfo}
        toggleInfoDrawer={() => {
          setOpenLayerModal(false);
          setOpenLegend(false);
          setOpenTrainList(false);
          setOpenTrainDrawer(false);
          setOpenAllPageDrawer(false);

          setOpenInfo(!openInfo);
        }}
      />
    </div>
  );
}

export default Home;
