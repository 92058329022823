import { connect } from 'react-redux';
import store from '../store/index';
import Search from './Search';

const mapStateToProps = (state) => ({
  trainsDEPRECATED: state.map.trains?.list,
});

const mapDispatchToProps = {
  setSelected: (payload) => store.setSelected(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
