import { Card, Row, Col, NavLink } from 'react-bootstrap';

import { format, isBefore, startOfDay } from 'date-fns';

export function AllPageCard({ data, onClick, isListView, isLast, cardRef }) {
  const today = startOfDay(new Date());
  const date = new Date(data.updatedAt);
  let formattedDate;
  if (isBefore(date, today)) {
    formattedDate = format(new Date(data.updatedAt), 'MMM. dd, h:mm a');
  } else {
    formattedDate = format(new Date(data.updatedAt), 'h:mm a');
  }

  const style = {};
  if (isLast && window.innerWidth < 768) {
    style.marginBottom = '90px';
  }

  return (
    <NavLink>
      <Card onClick={isListView && onClick} className="kvl-card" style={style} ref={cardRef}>
        <Card.Body className="kvl-card-body">
          <Row>
            <Col xs={9}>
              <div className="float-start kvl-card-header">
                {isListView &&
                  `${data.announcements.length > 1 ? 'Update - ' : ''}SF ${
                    data.severity
                  } / OPS ${data.operationalSeverity}: ${data.category.name}`}
              </div>
            </Col>
            <Col>
              <div className="float-end kvl-card-text">{formattedDate}</div>
            </Col>
          </Row>

          {data.assetInfo[0].display !== 'EMPTY_ASSET' && (
            <Card.Text className="kvl-card-text">{data.assetInfo[0].display}</Card.Text>
          )}
          <Card.Text className="kvl-card-text">{data.message}</Card.Text>
        </Card.Body>
      </Card>
    </NavLink>
  );
}
