// import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { uniqBy, maxBy } from 'lodash';

export const useDisruptionData = () => {
  const client = useQueryClient();
  const prevData = client.getQueryData(['disruptionDataExtended']);
  let mostRecentUpdate = null;
  const token = window.localStorage.getItem('keolisApiToken');

  if (prevData) {
    const sortedDateArray = prevData.map((obj) => obj.updatedAt).sort((a, b) => new Date(b) - new Date(a));
    // eslint-disable-next-line prefer-destructuring
    mostRecentUpdate = sortedDateArray[0];
  }

  const processData = (items) => {
    let result = items;
    if (items && items.length) {
      result = items.map((item) => {
        let latestAnnouncementDate = item.updatedAt || item.createdAt;
        if (item.announcements && item.announcements.length) {
          const latestAnnouncement = maxBy(item.announcements, 'createdAt');
          if (latestAnnouncement) {
            latestAnnouncementDate = latestAnnouncement.createdAt;
          }
        }

        return {
          ...item,
          updatedAt: latestAnnouncementDate,
        };
      });
    }

    return result;
  };

  const fetchDisruptionData = async () => {
    const response = await fetch(`${process.env.REACT_APP_KEOLIS_API_URL}disruptions/recent.json`, {
      headers: {
        ApplicationID: 'event_manager',
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();

    return processData(data);
  };

  const fetchRecentDisruptionData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_KEOLIS_API_URL}disruptions/recent.json?startDate=${mostRecentUpdate}`,
      {
        headers: {
          ApplicationID: 'event_manager',
          Authorization: token,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const data = await response.json();
    return uniqBy([...processData(data), ...prevData], 'disruptionId');
  };

  return useQuery(['disruptionDataExtended'], prevData?.length ? fetchRecentDisruptionData : fetchDisruptionData, {
    cacheTime: 1000 * 3600 * 24 * 2, // 2 days
    staleTime: 1000 * 30, // 30 seconds
    refetchInterval: 1000 * 30,
  });
};

/*
export const useDisruptionInbox = () => {
  const disruptionQuery = useDisruptionData();
  const [unread, setUnread] = useState(null);

  useEffect(() => {
    const initialUnreadData = localStorage.getItem('inboxUnreadItems');
    if (initialUnreadData) {
      setUnread(JSON.parse(initialUnreadData));
    }
  }, [disruptionQuery.data]);

  const markAsRead = (communicationId, announcementNumber) => {
    const updatedUnread = unread.filter(
      (item) => item.communicationId !== communicationId || item.announcementNumber !== announcementNumber,
    );
    setUnread(updatedUnread);
    localStorage.setItem('inboxUnreadItems', JSON.stringify(updatedUnread));
  };
};
*/
