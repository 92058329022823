import { useState, useEffect, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import { WebPushClient } from '@magicbell/webpush';
import { trackMessage } from './utils';

/*
async function deleteSubscriptions() {
  const keolisApiToken = window.localStorage.getItem('keolisApiToken');

  try {
    const result = await fetch(`${process.env.REACT_APP_KEOLIS_API_URL}users/web-push-subscriptions.json`, {
      method: 'DELETE',
      headers: {
        ApplicationID: 'event_manager',
        Authorization: keolisApiToken,
      },
    });
    if (result.ok) {
      return true;
    }
    throw new Error('Failed to delete subscriptions.');
  } catch (error) {
    console.error(error);
  }

  return false;
}
*/

async function ensureUserExists() {
  const keolisApiToken = window.localStorage.getItem('keolisApiToken');

  try {
    const result = await fetch(`${process.env.REACT_APP_KEOLIS_API_URL}users/web-push-user.json`, {
      method: 'POST',
      headers: {
        ApplicationID: 'event_manager',
        Authorization: keolisApiToken,
      },
    });
    trackMessage(`web-push-user response: ${result.status}`);
    if (result.ok) {
      return true;
    }
    throw new Error('Failed to create web push user.');
  } catch (error) {
    console.error(error);
  }

  return false;
}

/*
 * How we check to see if a user is subscribed to notifications:
 *
 * <answer here>
 *
 * How we subscribe a user to notifications:
 *
 * <answer here>
 *
 * How we unsubscribe a user from notifications:
 *
 * <answer here>
 *
 * It's easy to know if the user is subscribed to MagicBell notifications, but
 * how do we know they are subscribed on this device?:
 *
 * <answer here>
 *
 */
function MagicBellSubscription({ userKey, userExternalId, apiKey }) {
  /*
  See MagicBell reference implementation at https://www.magicbell.com/blog/web-push-notifications-from-your-domain
  */
  const [subscribed, setSubscribed] = useState(false);
  const [subscribedOnDevice, setSubscribedOnDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const wpClientRef = useRef(null);

  useEffect(() => {
    (async () => {
      trackMessage('Creating WebPushClient instance...');
      let wpClient;
      try {
        wpClient = new WebPushClient({
          apiKey,
          userExternalId,
          userHmac: userKey,
          serviceWorkerPath: './service-worker.js',
        });
        wpClientRef.current = wpClient;
      } catch (error) {
        trackMessage(`Error creating WebPushClient instance: ${error?.message}`);
      }

      trackMessage('Created WebPushClient instance.');

      setLoading(true);

      trackMessage('Ensuring magicbell user exists...');
      await ensureUserExists();
      trackMessage('Ensured magicbell user exists.');

      trackMessage('Checking for active magicbell subscription...');

      let subscribedResult = false;

      try {
        trackMessage(`Wait for service worker to be ready...`);
        const reg = await navigator.serviceWorker.ready;
        trackMessage(`Service worker status: ${reg.active.state}`);
        trackMessage(`Service worker scope: ${reg.scope}`);
        // const reg = await navigator.serviceWorker.getRegistration();
        window.nn_serviceWorkerRegistration = reg;
        trackMessage(`Get push subscription...`);
        const pushSubscription = await reg.pushManager.getSubscription();

        console.log(pushSubscription);

        trackMessage(`Call isSubscribed...`);
        subscribedResult = await wpClient.isSubscribed();
        trackMessage(`isSubscribed result: ${subscribedResult}`);
      } catch (error) {
        trackMessage(`IsSubscribed call timed out: ${error?.message}`);
      }

      trackMessage('Checked for active magicbell subscription.');

      trackMessage('Setting subscribed and loading state...');
      setSubscribed(subscribedResult);
      setLoading(false);
      trackMessage('Set subscribed and loading state.');

      trackMessage('Checking localStorage for subscribedOnDevice value...');
      if (localStorage.getItem('subscribedOnDevice') === 'true') {
        trackMessage(`localStorage subscribedOnDevice value: ${localStorage.getItem('subscribedOnDevice')}`);
        setSubscribedOnDevice(true);
      }
      trackMessage('Checked localStorage for subscribedOnDevice value.');
    })();
  }, []);

  const handleSubscribe = async () => {
    trackMessage('handleSubscribe called.');

    setLoading(true);

    trackMessage(`localStorage subscribedOnDevice value: ${localStorage.getItem('subscribedOnDevice')}`);
    trackMessage('awaiting webPushClient subscribe call...');

    try {
      await wpClientRef.current.subscribe();
    } catch (error) {
      trackMessage(`webPushClient subscribe call failed: ${error?.message}`);
    }

    trackMessage('completed webPushClient subscribe call.');
    setSubscribed(true);
    setSubscribedOnDevice(true);
    localStorage.setItem('subscribedOnDevice', 'true');

    // await changeSubscription({ userKey, userExternalId, apiKey, enabled: true });

    setLoading(false);
  };

  const handleUnsubscribe = async () => {
    setLoading(true);

    try {
      trackMessage(`Calling unsubscribe...`);
      await wpClientRef.current.unsubscribe();
      trackMessage(`Called unsubscribe.`);
      setSubscribed(false);
      setSubscribedOnDevice(false);
      localStorage.removeItem('subscribedOnDevice');
    } catch (error) {
      trackMessage(`webPushClient unsubscribe call failed: ${error?.message}`);
    }

    setLoading(false);
  };

  return (
    <div className="mt-4" style={{ width: '230px' }}>
      {subscribed ? (
        <div className="w-100">
          {!subscribedOnDevice && (
            <>
              <Button onClick={() => handleSubscribe()} className="w-100 mb-4" variant="info" disabled={loading}>
                {loading ? (
                  <Spinner as="span" animation="border" role="status" size="sm" aria-hidden="true" />
                ) : (
                  <span>Enable notifications on this device</span>
                )}
              </Button>
              <div className="mb-4">
                You are subscribed to notifications, but not on this device. Use the button above to enable
                notifications on this device.
              </div>
            </>
          )}

          <Button onClick={() => handleUnsubscribe()} className="w-100" variant="danger" disabled={loading}>
            {loading ? (
              <Spinner as="span" animation="border" role="status" size="sm" aria-hidden="true" />
            ) : (
              <span>Unsubscribe from notifications on all devices</span>
            )}
          </Button>
        </div>
      ) : (
        <Button onClick={() => handleSubscribe()} className="w-100" variant="info" disabled={loading}>
          {loading ? (
            <Spinner as="span" animation="border" role="status" size="sm" aria-hidden="true" />
          ) : (
            <span>Subscribe to notifications</span>
          )}
        </Button>
      )}
    </div>
  );
}

export default MagicBellSubscription;
