import React, { useEffect, useState, createRef } from 'react';
import { Container, Form, Spinner, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { AllPageDropdown } from './components/dropdown';
import { ArrowIcon } from './components/arrowIcon';
import { AllPageList } from './components/list';
import './style.css';
import { useDisruptionData } from './requests';
// import { updateAppBadge } from '../utils';

export function AllPage({ setOpenAllPageDrawer, notificationUrl, setNotificationUrl }) {
  const [searchFilter, setSearchFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [listView, setListView] = useState(true);
  const [detailView, setDetailView] = useState(null);
  const [refs, setRefs] = useState(null);
  const [refetching, setRefetching] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading, isError, data, refetch } = useDisruptionData();
  console.log('🚀 ~ AllPage ~ isLoading:', isLoading);

  useEffect(() => {
    const newRefs = data?.reduce((acc, value) => {
      acc[value.disruptionId] = createRef();
      return acc;
    }, {});

    setRefs(newRefs);
  }, [data, isLoading]);

  useEffect(() => {
    if (detailView?.disruptionId && listView) {
      refs[detailView.disruptionId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [detailView, listView]);

  useEffect(() => {
    if (notificationUrl && data && data.length) {
      const urlObject = new URL(notificationUrl);
      const params = new URLSearchParams(urlObject.search);
      const commId = params.get('commId');
      const announcementId = params.get('announcementId');

      // check if notifcationUrl exists in prevData
      const found = data.find(
        (comm) =>
          comm.disruptionId === parseInt(commId, 10) &&
          comm.announcements.find((announcement) => announcement.displayId === parseInt(announcementId, 10)),
      );

      if (found) {
        setDetailView(found);
        setListView(false);
      } else if (!found) {
        refetch().then((promise) => {
          const foundData = promise.data.find((comm) => comm.disruptionId === parseInt(commId, 10));
          setDetailView(foundData);
          setListView(false);
        });
      }
      setNotificationUrl(null);
    }
  }, [notificationUrl, data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Remove the notification item from local storage to indicate that it has been handled
      // but only after the component has actually been rendered for 10 seconds
      // which should prevent a premature removal of the "initialNotificaionUrl"
      // when any sort of sign-in redirection is happening

      localStorage.removeItem('initialNotificationUrl');
    }, 10000);

    // This should cancel the function within setTimeout when the component is unmounted
    // during any sort of sign-in redirection and pop the drawer open when you are
    // finally redirected back
    return () => clearTimeout(timer);
  }, []);

  const standaloneStyle = {};
  if (window.navigator.standalone) {
    standaloneStyle.paddingTop = '60px';
  }

  const handleRefetch = async () => {
    setRefetching(true);
    await queryClient.invalidateQueries(['disruptionDataExtended']).then(() => setRefetching(false));
  };

  return (
    <Container className="p-3">
      {listView && refs && (
        <div style={{ paddingTop: '60px' }}>
          <div className="pb-3 d-flex justify-content-between">
            <div>
              <h1>All Pages</h1>
            </div>
            <div className="d-flex align-items-center">
              <Button
                variant="outline-dark"
                className="all-page-close-button"
                onClick={() => setOpenAllPageDrawer(false)}
              >
                Close
              </Button>
              <AllPageDropdown setDateFilter={setDateFilter} />
            </div>
          </div>

          <Form>
            <Form.Group className="mb-3" controlId="search">
              <Form.Control
                placeholder="Filter All Pages..."
                type="text"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </Form.Group>
          </Form>

          {(isLoading || refetching) && (
            <div className="d-flex justify-content-center pt-5">
              <Spinner animation="border" />
            </div>
          )}

          {isError && (
            <div>
              <div className="d-flex justify-content-center p-1">There was an issue loading the page.</div>
              <div className="d-flex justify-content-center p-1">
                <Button variant="outline-dark" onClick={() => handleRefetch()}>
                  Refresh All Pages list
                </Button>
              </div>
            </div>
          )}

          <AllPageList
            pages={data}
            refs={refs}
            setDetailView={setDetailView}
            setListView={setListView}
            searchFilter={searchFilter}
            dateFilter={dateFilter}
          />
        </div>
      )}
      {!listView && (
        <div style={{ paddingTop: '60px' }}>
          <div className="d-flex justify-content-between">
            <Button
              variant="light"
              onClick={() => {
                setListView(true);
              }}
            >
              <ArrowIcon />
            </Button>
            <div style={{ width: '100px' }}>
              <Button className="w-100" variant="outline-dark" onClick={() => setOpenAllPageDrawer(false)}>
                Close
              </Button>
            </div>
          </div>
          <Container className="p-3">
            {detailView &&
              detailView.announcements
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((announcement, index) => {
                  const style = {};
                  const isLast = index === detailView.announcements.length - 1;
                  if (isLast && window.innerWidth < 768) {
                    style.marginBottom = '90px';
                  }
                  return (
                    <div
                      className="kvl-detail-card"
                      key={announcement.announcementId}
                      dangerouslySetInnerHTML={{
                        __html: announcement.messageText,
                      }}
                      style={style}
                    />
                  );
                })}
          </Container>
        </div>
      )}
    </Container>
  );
}
