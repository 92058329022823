import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

import { isTokenValid } from './utils';

function Login() {
  const [shouldLogin, setShouldLogin] = useState(false);
  const history = useHistory();
  const { instance: msalInstance } = useMsal();
  const keolisApiToken = window.localStorage.getItem('keolisApiToken');

  useEffect(() => {
    // const account = msalInstance.current.getAccount();

    if (history?.location?.state?.forceLogin) {
      console.log('Initiating interactive login flow...');
      setShouldLogin(true);
      return;
    }

    if (!keolisApiToken || !isTokenValid(keolisApiToken)) {
      msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          let accountObj = null;
          if (tokenResponse !== null) {
            accountObj = tokenResponse.account;
            const { idToken } = tokenResponse;
            const { accessToken } = tokenResponse;
            console.log('MSAL id token:', idToken);
            console.log('MSAL access token:', accessToken);

            window.localStorage.setItem('keolisApiToken', idToken);
            window.localStorage.setItem('keolisApiMapLayerToken', accessToken);

            // Exchange our Azure AD ID token for a Keolis API token
            /* return fetch(process.env.REACT_APP_KEOLIS_API_URL + 'auth/exchange.json', {
					method: 'POST',
					body: JSON.stringify({ idToken: idToken })
				  }).then((exchangeResponse) => {
					return exchangeResponse.json().then(function (data) {
					  alert('fetched API token');
					  window.localStorage.setItem('keolisApiToken', data.token)
					  history.replace("/home");
					});
				  }); */

            const { username } = accountObj;
            console.log(username);
            history.replace('/home');
          } else {
            // No redirect to handle, so we need to start the login flow
            setShouldLogin(true);
          }
        })
        .catch((err) => {
          console.log('Error handling token redirect');
          console.log(err);
          // window.localStorage.clear();
          history.replace('/', { forceLogin: true });
        });
    } else {
      history.replace('/home');
    }
    // eslint-disable-next-line
	}, []);

  useEffect(() => {
    const request = {
      scopes: [process.env.REACT_APP_AZURE_AD_TOKEN_SCOPES],
    };

    if (shouldLogin) {
      msalInstance.loginRedirect(request);
    }
  }, [shouldLogin]);
}

export default Login;
