"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.epic = exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _reduxObservable = require("redux-observable");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _moment = _interopRequireDefault(require("moment"));
var _api = _interopRequireDefault(require("../../api"));
var _utilities = require("../../utilities");
var _actions = require("../actions");
var _layers = require("../layers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// ACTIONS
var actionTypes = {
  LOAD_CURRENT_EQUIPMENT: 'KEOLIS-MAP/LOAD_CURRENT_EQUIPMENT',
  SET_EQUIPMENT: 'KEOLIS-MAP/SET_EQUIPMENT',
  SET_EQUIPMENT_WITH_TIMESTAMP: 'KEOLIS-MAP/SET_EQUIPMENT_WITH_TIMESTAMP',
  SET_EQUIPMENT_LOADING: 'KEOLIS-MAP/SET_EQUIPMENT_LOADING'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  setEquipment: function setEquipment(payload) {
    return {
      type: actionTypes.SET_EQUIPMENT,
      payload: payload
    };
  },
  loadCurrentEquipment: function loadCurrentEquipment(p) {
    return {
      type: actionTypes.LOAD_CURRENT_EQUIPMENT,
      payload: {}
    };
  },
  setEquipmentWithTimestamp: function setEquipmentWithTimestamp(payload) {
    return {
      type: actionTypes.SET_EQUIPMENT_WITH_TIMESTAMP,
      payload: payload
    };
  },
  setEquipmentLoading: function setEquipmentLoading(payload) {
    return {
      type: actionTypes.SET_EQUIPMENT_LOADING,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.LOAD_CURRENT_EQUIPMENT:
      return (0, _assign["default"])({}, state, {
        isLoading: true
      });
    case _actions.SET_TIMESTAMP:
      return (0, _assign["default"])({}, state, {
        cache: {},
        list: action.payload ? [] : state.list
      });
    case actionTypes.SET_EQUIPMENT:
      var items = action.payload;
      return (0, _assign["default"])({}, state, {
        cache: (0, _utilities.reduceEquipmentList)({}, items),
        list: items,
        isLoading: false,
        maxLoadedDate: null
      });
    case actionTypes.SET_EQUIPMENT_WITH_TIMESTAMP:
      var _action$payload = action.payload,
        list = _action$payload.list,
        timestamp = _action$payload.timestamp;
      return (0, _assign["default"])({}, state, {
        cache: (0, _utilities.reduceEquipmentList)({}, list),
        list: list,
        isLoading: false,
        maxLoadedDate: timestamp,
        isBuffering: false
      });
    case actionTypes.SET_EQUIPMENT_LOADING:
      return (0, _assign["default"])({}, state, {
        isLoading: true
      });
    default:
      return state;
  }
}

// EPICS

var baseTriggerActions = [_actions.SET_TIMESTAMP, _layers.actionTypes.SET_LAYERS, _layers.actionTypes.TOGGLE_ALL, _layers.actionTypes.TOGGLE_LAYER];

// EPIC
var loadCurrentEpic = function loadCurrentEpic(action$, store) {
  return action$.ofType(actionTypes.LOAD_CURRENT_EQUIPMENT).switchMap(function (action) {
    return (0, _api["default"])(store).get("system/equipment/locations/snapshot.json?date=".concat((0, _utilities.formatMoment)((0, _moment["default"])()))).map(function (result) {
      return result.response;
    }).map(function (items) {
      return actions.setEquipment(items);
    })["catch"](function (error) {
      console.log(error);
      return _rxjs.Observable.of({}).ignoreElements();
    });
  });
};
var loadTimestampEpic = function loadTimestampEpic(action$, store) {
  return action$.filter(function (action) {
    return (0, _includes["default"])(baseTriggerActions, action.type);
  })
  // check if this for a timestamp in the past (will be null for current)
  .filter(function (action) {
    return action.type == _actions.SET_TIMESTAMP ? action.payload : true;
  })
  // Only do this if the layer is visible
  .filter(function (action) {
    return store.getState().map.layers.equipment && store.getState().map.playback.timestamp;
  }).map(function (action) {
    return action.type == _actions.SET_TIMESTAMP ? action : (0, _assign["default"])({}, action, {
      payload: store.getState().map.playback.timestamp
    });
  }).switchMap(function (action) {
    return (0, _api["default"])(store).get("system/equipment/locations/snapshot.json?date=".concat((0, _utilities.formatMoment)(action.payload))).map(function (result) {
      return result.response;
    }).map(function (items) {
      return actions.setEquipmentWithTimestamp({
        list: items,
        timestamp: action.payload
      });
    })["catch"](function (error) {
      console.log(error);
      return _rxjs.Observable.of({}).ignoreElements();
    }).startWith(actions.setEquipmentLoading());
  });
};
var epic = (0, _reduxObservable.combineEpics)(loadCurrentEpic, loadTimestampEpic);
exports.epic = epic;