"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Vector = _interopRequireDefault(require("ol/layer/Vector"));
var _Feature = _interopRequireDefault(require("ol/Feature"));
var _Point = _interopRequireDefault(require("ol/geom/Point.js"));
var _Projection = _interopRequireDefault(require("ol/proj/Projection.js"));
var _Vector2 = _interopRequireDefault(require("ol/source/Vector.js"));
var _Style = _interopRequireDefault(require("ol/style/Style.js"));
var _Icon = _interopRequireDefault(require("ol/style/Icon.js"));
var _Text = _interopRequireDefault(require("ol/style/Text.js"));
var _Fill = _interopRequireDefault(require("ol/style/Fill.js"));
var _Stroke = _interopRequireDefault(require("ol/style/Stroke.js"));
var _GeoJSON = _interopRequireDefault(require("ol/format/GeoJSON.js"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _partition3 = _interopRequireDefault(require("lodash/partition"));
var _helpers = require("./helpers.js");
var _map = require("../map");
var _incident = _interopRequireDefault(require("../../../../assets/icons/incident.png"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } //import ol from "openlayers";
var incidentIconStyle = new _Style["default"]({
  image: new _Icon["default"]( /** @type {olx.style.IconOptions} */{
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 1,
    src: _incident["default"]
  })
});
var layer = new _Vector["default"]({
  visible: true
});
_map.map.addLayer(layer);
var incident = {
  handleUpdate: function handleUpdate(_ref) {
    var incident = _ref.incident,
      mileposts = _ref.mileposts,
      locations = _ref.locations,
      milepostGroups = _ref.milepostGroups;
    if (!incident || (0, _isEmpty["default"])(milepostGroups)) return;
    var _partition = (0, _partition3["default"])(mileposts, function (m) {
        return m.isStation;
      }),
      _partition2 = _slicedToArray(_partition, 2),
      stations = _partition2[0],
      milepostsWithoutStations = _partition2[1];
    var collections = {
      mileposts: milepostsWithoutStations,
      stations: stations,
      locations: locations,
      milepostGroups: milepostGroups
    };
    var features = (0, _helpers.getFeaturesFromObject)(incident, collections);

    //set the style
    features.forEach(function (feature) {
      return feature.setStyle(incidentIconStyle);
    });
    var source = new _Vector2["default"]({
      features: features
    });
    layer.setSource(source);
  }
};
var _default = incident;
exports["default"] = _default;