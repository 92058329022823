"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _map = require("../map");
var _Vector = _interopRequireDefault(require("ol/layer/Vector"));
var _Feature = _interopRequireDefault(require("ol/Feature"));
var _Point = _interopRequireDefault(require("ol/geom/Point.js"));
var _Projection = _interopRequireDefault(require("ol/proj/Projection.js"));
var _proj = require("ol/proj");
var _Vector2 = _interopRequireDefault(require("ol/source/Vector.js"));
var _Style = _interopRequireDefault(require("ol/style/Style.js"));
var _Icon = _interopRequireDefault(require("ol/style/Icon.js"));
var _Circle = _interopRequireDefault(require("ol/style/Circle.js"));
var _Text = _interopRequireDefault(require("ol/style/Text.js"));
var _Fill = _interopRequireDefault(require("ol/style/Fill.js"));
var _Stroke = _interopRequireDefault(require("ol/style/Stroke.js"));
var _GeoJSON = _interopRequireDefault(require("ol/format/GeoJSON.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// import ol from "openlayers";

var layer = new _Vector["default"]({
  visible: false,
  name: "mileposts"
});
_map.map.addLayer(layer);
var mileposts = {
  handleUpdate: function handleUpdate(_ref) {
    var layers = _ref.layers,
      mileposts = _ref.mileposts;
    layer.setVisible(layers === null || layers === void 0 ? void 0 : layers.mileposts);

    //early cutoff for when layer is not visible or unavailable
    if (!(layers !== null && layers !== void 0 && layers.mileposts) || !mileposts) return;

    // Otherwise, update locations
    var features = mileposts.filter(function (milepost) {
      return !milepost.isStation && milepost.latLong[0] && milepost.latLong[1];
    }) // Filter out mileposts without a location
    .map(function (milepost) {
      // Create feature with location
      var feature = new _Feature["default"]({
        geometry: new _Point["default"]((0, _proj.transform)([milepost.latLong[1], milepost.latLong[0]], "EPSG:4326", "EPSG:900913")),
        name: milepost.milepost + (milepost.name ? " - " + milepost.name : "")
      });
      feature.setProperties(milepost);
      feature.setStyle(milepostStyleFunction);
      feature.setId("milepost-" + milepost.milepostId);
      return feature;
    });

    // set the features to this layer
    layer.setSource(new _Vector2["default"]({
      features: features
    }));
  }
};
var _default = mileposts; // STYLES
exports["default"] = _default;
var milepostStyleCache = {};
var milepostStyleFunction = function milepostStyleFunction(resolution) {
  var feature = this;
  var radius = Math.log(4 * 612 / resolution);
  if (radius < 1) {
    radius = 0.5;
  }
  if (!milepostStyleCache[radius]) {
    milepostStyleCache[radius] = [new _Style["default"]({
      image: new _Circle["default"]({
        radius: radius,
        fill: new _Fill["default"]({
          color: 'rgba(0,0,0,1)'
        }),
        stroke: new _Stroke["default"]({
          color: 'rgba(0,0,0,1)',
          width: 1
        })
      })
    })];
  }
  return milepostStyleCache[radius];
};