import { createSelector } from 'reselect';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';

export const authSelector = state => state.auth

export const entitiesSelector = state => state.entities

export const statusSelector = state => state.status

export const noticesSelector = state => state.notices


// AUTH

export const getAuthToken = createSelector(
  authSelector,
  auth => auth.token
)

export const isNotViewer = createSelector(
  authSelector,
  auth => auth && auth.user && auth.user.role != 'viewer'
);

// STATUS

export const getStatusFactory = slice => createSelector(
  statusSelector,
  status => status && status[slice] ? status[slice] : {}
)

// USERS
export const usersSelector = createSelector(
  entitiesSelector,
  entities => entities.users
)

export const getUsersList = createSelector(
  usersSelector,
  users => values(users)
);

export const getUser = (userId) => createSelector(
  usersSelector,
  users => users ? users[userId] : {}
);

// Messages

export const messagesSelector = createSelector(
  entitiesSelector,
  entities => entities.messages
)

export const getMessagesList = createSelector(
  messagesSelector,
  messages => values(messages)
)

// ROLES
export const rolesSelector = createSelector(
  entitiesSelector,
  entities => entities.roles
)
export const getRoleList = createSelector(
  rolesSelector,
  roles => values(roles)
);
export const getRoleMap = createSelector(
  rolesSelector,
  roles => mapValues(roles, role => role.name)
);

// Categories

export const categoriesSelector = createSelector(
  entitiesSelector,
  entities => entities.categories
)

export const getCategoryList = createSelector(
  categoriesSelector,
  categories => values(categories)
);

export const getCategory = (categoryId) => createSelector(
  categoriesSelector,
  categories => categories ? categories[categoryId] : {}
);

// Settings
export const settingsSelector = createSelector(
  entitiesSelector,
  entities => entities.settings
)

// Disruptions

export const disruptionsSelector = createSelector(
  entitiesSelector,
  entities => entities.disruptions
)

export const getDisruptionList = createSelector(
  disruptionsSelector,
  disruptions => values(disruptions)
);

export const getDisruption = (disruptionId) => createSelector(
  disruptionsSelector,
  disruptions => disruptions ? disruptions[disruptionId] : {}
);
