"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapStateReducer = exports.INITIAL_MAP_STATE = void 0;
var _lodash = require("lodash");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var INITIAL_MAP_STATE = {
  selected: {
    id: null,
    type: null
  },
  hovered: null,
  timestamp: null,
  options: {
    type: "geo-map",
    showLegend: false,
    showLayerModal: false,
    useDefaultCommuteSource: true,
    commuteWsUrl: "wss://keolis-map.bowst.com",
    commuteApiUrl: "https://keolis-map.bowst.com"
  },
  auth: {
    canTag: false,
    commuteApiUrl: null,
    commuteWsUrl: null,
    keolisApiUrl: null,
    mapLayerToken: null,
    token: null
  },
  playback: {
    showScrubber: false,
    isPlaying: false,
    from: null,
    to: null,
    timestamp: null
  },
  trains: null
};
exports.INITIAL_MAP_STATE = INITIAL_MAP_STATE;
var mapStateReducer = function mapStateReducer(state, action) {
  var nextState = state;
  switch (action.type) {
    case 'RESET_MAP_STATE':
      {
        nextState = INITIAL_MAP_STATE;
        break;
      }
    case 'UNSELECT':
      {
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          selected: {
            id: null,
            type: null
          }
        });
        break;
      }
    case 'SET_SELECTED':
      {
        var nextSelected = action.payload.nextSelected;
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          selected: nextSelected
        });
        break;
      }
    case 'SET_HOVERED':
      {
        var nextHovered = action.payload.nextHovered;
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          hovered: nextHovered
        });
        break;
      }
    case 'SET_AUTH_TOKEN':
      {
        var authToken = action.payload.authToken;
        var currentAuth = _objectSpread({}, nextState.auth);
        var nextAuth = _objectSpread(_objectSpread({}, currentAuth), {}, {
          token: authToken
        });
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          auth: nextAuth
        });
        break;
      }
    case 'SET_MAP_LAYER_AUTH_TOKEN':
      {
        var mapAuthToken = action.payload.mapAuthToken;
        var _currentAuth = _objectSpread({}, nextState.auth);
        var _nextAuth = _objectSpread(_objectSpread({}, _currentAuth), {}, {
          mapLayerToken: mapAuthToken
        });
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          auth: _nextAuth
        });
        break;
      }
    case 'SET_CAN_TAG':
      {
        var canTag = action.payload.canTag;
        var _currentAuth2 = _objectSpread({}, nextState.auth);
        var _nextAuth2 = _objectSpread(_objectSpread({}, _currentAuth2), {}, {
          canTag: canTag
        });
        nextState = _objectSpread(_objectSpread({}, nextState), {}, {
          auth: _nextAuth2
        });
        break;
      }
    case 'SHOW_SCRUBBER':
      {
        var currentPlayback = _objectSpread({}, state.playback);
        var nextPlayback = _objectSpread(_objectSpread({}, currentPlayback), {}, {
          showScrubber: true
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: nextPlayback
        });
        break;
      }
    case 'HIDE_SCRUBBER':
      {
        var _currentPlayback = _objectSpread({}, state.playback);
        var _nextPlayback = _objectSpread(_objectSpread({}, _currentPlayback), {}, {
          showScrubber: false
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback
        });
        break;
      }
    case 'START_PLAYBACK':
      {
        var _currentPlayback2 = _objectSpread({}, state.playback);
        var _nextPlayback2 = _objectSpread(_objectSpread({}, _currentPlayback2), {}, {
          isPlaying: true
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback2
        });
        break;
      }
    case 'STOP_PLAYBACK':
      {
        var _currentPlayback3 = _objectSpread({}, state.playback);
        var _nextPlayback3 = _objectSpread(_objectSpread({}, _currentPlayback3), {}, {
          isPlaying: false
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback3
        });
        break;
      }
    case 'SET_PLAYBACK_RANGE':
      {
        var _action$payload = action.payload,
          from = _action$payload.from,
          to = _action$payload.to;
        var _currentPlayback4 = _objectSpread({}, state.playback);
        var _nextPlayback4 = _objectSpread(_objectSpread({}, _currentPlayback4), {}, {
          from: from,
          to: to
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback4
        });
        break;
      }
    case 'SET_TIMESTAMP':
      {
        var timestamp = action.payload.timestamp;
        var _currentPlayback5 = _objectSpread({}, state.playback);
        var _nextPlayback5 = _objectSpread(_objectSpread({}, _currentPlayback5), {}, {
          timestamp: timestamp
        });
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback5
        });
        break;
      }
    case 'RESET_PLAYBACK':
      {
        var _nextPlayback6 = {
          showScrubber: false,
          isPlaying: false,
          from: null,
          to: null,
          timestamp: null
        };
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          playback: _nextPlayback6
        });
        break;
      }
    case 'SET_TRAINS':
      {
        var trains = action.payload.trains;
        nextState = _objectSpread(_objectSpread({}, state), {}, {
          trains: trains
        });
        break;
      }
    default:
      throw new Error("Unknown action type encountered by mapReducer: [".concat(action.type, "]"));
  }
  return nextState;
};
exports.mapStateReducer = mapStateReducer;