"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authActions = exports.authActionTypes = exports.actions = exports.actionTypes = void 0;
exports.authReducer = authReducer;
exports.reducer = exports.epic = void 0;
var _assign = _interopRequireDefault(require("lodash/assign"));
var _reduxObservable = require("redux-observable");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var authActionTypes = {
  SET_AUTH_TOKEN: 'KEOLIS-MAP/SET_AUTH_TOKEN',
  SET_MAP_LAYER_AUTH_TOKEN: 'KEOLIS-MAP/SET_MAP_LAYER_AUTH_TOKEN',
  SET_CAN_TAG: 'KEOLIS-MAP/SET_CAN_TAG'
};

// ACTION GENERATORS
exports.authActionTypes = authActionTypes;
var authActions = {
  setAuthToken: function setAuthToken(payload) {
    return {
      type: actionTypes.SET_AUTH_TOKEN,
      payload: payload
    };
  },
  setMapLayerAuthToken: function setMapLayerAuthToken(payload) {
    return {
      type: actionTypes.SET_MAP_LAYER_AUTH_TOKEN,
      payload: payload
    };
  },
  setCanTag: function setCanTag(payload) {
    return {
      type: actionTypes.SET_CAN_TAG,
      payload: payload
    };
  }
};

// REDUCER
exports.authActions = authActions;
function authReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH_TOKEN:
      return (0, _assign["default"])({}, state, {
        token: action.payload
      });
    case actionTypes.SET_MAP_LAYER_AUTH_TOKEN:
      return (0, _assign["default"])({}, state, {
        mapLayerToken: action.payload
      });
    case actionTypes.SET_CAN_TAG:
      return (0, _assign["default"])({}, state, {
        canTag: action.payload
      });
    default:
      return state;
  }
}

// LIST OF SUBS
var list = [{
  actions: authActions,
  actionTypes: authActionTypes,
  reducer: authReducer
}];
var defaultInitialState = {
  token: 'NO-AUTH-TOKEN',
  canTag: false
};

// REDUCER
var reducer = function reducer(storeOptions) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _objectSpread(_objectSpread({}, defaultInitialState), storeOptions);
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return list.reduce(function (nextState, sub) {
      return sub.reducer(nextState, action);
    }, state);
  };
};

// ACTIONS
exports.reducer = reducer;
var actionTypes = list.reduce(function (acc, value) {
  return (0, _assign["default"])(acc, value.actionTypes);
}, {});

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = list.reduce(function (acc, value) {
  return (0, _assign["default"])(acc, value.actions);
}, {});

// EPICS
exports.actions = actions;
var epics = list.filter(function (l) {
  return l.epic;
}).map(function (l) {
  return l.epic;
});
var epic = _reduxObservable.combineEpics.apply(void 0, _toConsumableArray(epics));
exports.epic = epic;