export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const isTokenValid = (token) => {
  const { exp: expiration } = parseJwt(token);
  const currentTimestamp = Math.round(new Date().getTime() / 1000);
  // console.log('minutes until token expires:', ((expiration - currentTimestamp) / 60))
  return expiration - currentTimestamp > 600; /* 10 minutes in seconds */
};

export async function updateAppBadge(count) {
  if ('setAppBadge' in navigator) {
    try {
      await navigator.setAppBadge(count);
    } catch (error) {
      console.error('Failed to set app badge:', error);
    }
  }
}

export function trackMessage(message) {
  if (window.appInsights && window.appInsights?.SeverityLevel) {
    window.appInsights.trackTrace({ message, severityLevel: window.appInsights.SeverityLevel.Information });
  }
}
