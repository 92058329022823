import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { useMapState } from '@bowst/keolis-map-component/dist/desktop/hooks/mapState';

function Search({ trains, closeSearch }) {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  // eslint-disable-next-line
  const { mapState, newSetSelected } = useMapState();

  useEffect(() => {
    if (searchInput !== '' && trains?.length) {
      setSearchResults(
        trains.filter((train) => {
          if (!train) return false;

          const { properties } = train;
          const tripShortName = properties?.trip_short_name || '';
          const vehicleId = properties?.vehicle || '';
          return (
            tripShortName.includes(searchInput.toString()) ||
            (vehicleId.includes(searchInput.toString()) && tripShortName)
          );
        }),
      );
    } else {
      setSearchResults([]);
    }
    // eslint-disable-next-line
  }, [searchInput, trains])

  return (
    <div>
      <div className="m-3" style={{ paddingTop: '60px' }}>
        <Form className="search-form" onSubmit={(e) => e.preventDefault()}>
          <InputGroup>
            <FormControl
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
              size="lg"
              type="number"
              inputMode="numeric"
              min={0}
              placeholder="Train/Control Car Number"
              className="search-input"
            />
            <InputGroup.Text className="search-icon">
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup>
        </Form>
        {searchResults.length > 0 ? (
          <div className="d-flex align-items-center flex-column justify-content-center overflow-auto w-75 mx-auto mb-5">
            {searchResults.map((result) => (
              <Button
                key={result.properties.trip_short_name}
                className="train-button my-3 w-100"
                onClick={() => {
                  const featureProps = result.properties;
                  newSetSelected({
                    id: featureProps.trip_short_name ? featureProps.trip_short_name : null,
                    vehicle: featureProps.vehicle,
                    type: 'train',
                    tripShortName: featureProps.trip_short_name,
                    vehicle_status: featureProps.vehicle_status,
                    entireTrainObj: featureProps,
                  });
                  return closeSearch();
                }}
                block
              >
                {`Train: ${result.properties.trip_short_name}${result.properties.vehicle ? ` Control: ${result.properties.vehicle}` : ''}`}
              </Button>
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center m-3">No results</div>
        )}
      </div>
    </div>
  );
}

export default Search;
