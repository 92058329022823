"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.actionTypes = void 0;
exports.reducer = reducer;
var _rxjs = require("rxjs");
var _assign = _interopRequireDefault(require("lodash/assign"));
var _mapValues = _interopRequireDefault(require("lodash/mapValues"));
var _values = _interopRequireDefault(require("lodash/values"));
var _keys = _interopRequireDefault(require("lodash/keys"));
var _pick = _interopRequireDefault(require("lodash/pick"));
var _some = _interopRequireDefault(require("lodash/some"));
var _includes = _interopRequireDefault(require("lodash/includes"));
var _actions = require("./../actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
// LIBRARIES

// GlOBAL ACTIONS

// ACTIONS
var actionTypes = {
  SET_LAYERS: 'KEOLIS-MAP/SET_LAYERS'
};

// ACTION GENERATORS
exports.actionTypes = actionTypes;
var actions = {
  setLayers: function setLayers(payload) {
    return {
      type: actionTypes.SET_LAYERS,
      payload: payload
    };
  }
};

// REDUCER
exports.actions = actions;
function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_LAYERS:
      var layers = (0, _keys["default"])(state);
      return (0, _assign["default"])({}, state, (0, _pick["default"])(action.payload, layers));
    default:
      return state;
  }
}