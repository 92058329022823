/*eslint-disable */
import React, { Suspense, useState, useEffect, useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useMapStateProvider } from "@bowst/keolis-map-component/dist/desktop/hooks/mapState";
// import { queryClient } from '@bowst/keolis-map-component/dist/desktop/hooks/queries/queryClient';
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { QueryClient } from "@tanstack/react-query";
import { ErrorBoundary } from "./ErrorBoundary";
import Login from "./Login";
import Home from "./Home";
import AuthRedirect from "./AuthRedirect";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 3600 * 24 * 2, // 2 days
      staleTime: 1000 * 3600, // 1 hour
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const { MapStateContext, ...contextItems } = useMapStateProvider();
  const [subscribed, setSubscribed] = useState(false);

  const [notificationUrl, setNotificationUrl] = useState();
  const [windowLocationHref, setWindowLocationHref] = useState(window.location.href);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (windowLocationHref !== window.location.href) {
        setWindowLocationHref(window.location.href);
      }
    }, 100);

    // Right away, we want to stash this notification in local storage
    // for safe keeping. The reason we do this is because the user's
    // session may not be active yet, which will cause the user's
    // browser to be redirected to the sign-in endpoint. When the user
    // returns to the app after signing in, we can check for this notification
    // URL in local storage and handle displaying it.
    if (windowLocationHref.includes('commId')) {
      localStorage.setItem('initialNotificationUrl', windowLocationHref);
    } else {
      if (localStorage.getItem('initialNotificationUrl')) {
        // we have a stashed-away notification URL, so we will set that in our
        // state, which will cause it to render in the AllPage component.
        // The AllPage component will take care of removing this notification
        // URL from local storage after it has handled it so that we don't keep
        // showing the same notification over and over.
        setNotificationUrl(localStorage.getItem('initialNotificationUrl'));
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (windowLocationHref.includes('commId')) {
      setNotificationUrl(windowLocationHref);
    }

  }, [windowLocationHref]);



  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      {/* eslint-disable-next-line */}
      <MapStateContext.Provider value={{ ...contextItems }}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route path="/redirect">
                <AuthRedirect />
              </Route>
              <Route exact path="/home">
                <ErrorBoundary>
                  <Home
                  notificationUrl={notificationUrl}
                  setNotificationUrl={setNotificationUrl}
                  />
                </ErrorBoundary>
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </MapStateContext.Provider>
    </PersistQueryClientProvider>
  );
}

export default App;
