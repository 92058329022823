import Stack from 'react-bootstrap/Stack';
import { subDays, startOfToday } from 'date-fns';
import { AllPageCard } from './card';

export function AllPageList({ pages, setDetailView, setListView, searchFilter, dateFilter, refs }) {
  return (
    <Stack gap={1}>
      {pages &&
        pages
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .filter((page) => {
            if (dateFilter === 'today') {
              return (
                new Date(page.createdOn) >= startOfToday() &&
                page.message.toLowerCase().includes(searchFilter.toLowerCase())
              );
            }
            if (dateFilter === '7') {
              return (
                new Date(page.createdOn) > subDays(new Date(), 7) &&
                page.message.toLowerCase().includes(searchFilter.toLowerCase())
              );
            }
            if (dateFilter === '30') {
              return (
                new Date(page.createdOn) > subDays(new Date(), 30) &&
                page.message.toLowerCase().includes(searchFilter.toLowerCase())
              );
            }
            return page.message.toLowerCase().includes(searchFilter.toLowerCase());
          })
          .map((page, index) => {
            const isLast = index === pages.length - 1;
            return (
              <AllPageCard
                data={page}
                isLast={isLast}
                isListView
                key={page.disruptionId}
                cardRef={refs[page.disruptionId]}
                onClick={() => {
                  setDetailView(page);
                  setListView(false);
                }}
              />
            );
          })}
    </Stack>
  );
}
