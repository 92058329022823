import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

function AuthRedirect() {
  const { instance: msalInstance } = useMsal();
  const history = useHistory();

  msalInstance
    .handleRedirectPromise()
    .then((tokenResponse) => {
      if (tokenResponse !== null) {
        const { idToken } = tokenResponse;
        const { accessToken } = tokenResponse;

        window.localStorage.setItem('keolisApiToken', idToken);
        window.localStorage.setItem('keolisApiMapLayerToken', accessToken);

        // Exchange our Azure AD ID token for a Keolis API token
        /* return fetch(process.env.REACT_APP_KEOLIS_API_URL + 'auth/exchange.json', {
        method: 'POST',
        body: JSON.stringify({ idToken: idToken })
      }).then((exchangeResponse) => {
        return exchangeResponse.json().then(function (data) {
          alert('fetched API token');
          window.localStorage.setItem('keolisApiToken', data.token)
          history.push("/home");
        });
      }); */

        history.replace('/home');
      } else {
        // Redirect back to our login screen if we didn't manage to
        // successfully authenticate
        history.replace('/');
      }
    })
    .catch((error) => error);
}

export default AuthRedirect;
