import Dropdown from 'react-bootstrap/Dropdown';
import { MenuIcon } from './menuIcon';

export function AllPageDropdown({ setDateFilter }) {
  return (
    <Dropdown className="menu-button">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        <MenuIcon />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setDateFilter('today')}>Today</Dropdown.Item>
        <Dropdown.Item onClick={() => setDateFilter('7')}>Last 7 Days</Dropdown.Item>
        <Dropdown.Item onClick={() => setDateFilter('30')}>Last 30 Days</Dropdown.Item>
        <Dropdown.Item onClick={() => setDateFilter('')}>Clear Filter</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
